import {ss} from '@/utils/storage'

const LOCAL_NAME = 'userStorage'

export interface UserInfo {
	id: string
	avatar: string
	name: string
	phone: string
	role: string
	description: string
	openId: string
}

export interface UserState {
	userInfo: UserInfo
}

export function defaultSetting(): UserState {
	return {
		userInfo: {
			id: '',
			avatar: 'https://chat-gpt-1252138783.cos.ap-chengdu.myqcloud.com/defaultHeadImg.jpg',
			name: '访客',
			phone: '',
			role: '普通会员',
			description: '',
			openId: '',
			// description: 'Star on <a href="https://github.com/hxxb/chatgpt-web" class="text-blue-500" target="_blank" >Github</a>',
		},
	}
}

export function getLocalState(): UserState {
	const localSetting: UserState | undefined = ss.get(LOCAL_NAME)
	return {...defaultSetting(), ...localSetting}
}

export function setLocalState(setting: UserState): void {
	ss.set(LOCAL_NAME, setting)
}
