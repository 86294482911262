<script setup lang="ts">
import Cookies from 'js-cookie'
import {computed, ref} from 'vue'
import {Vue3Marquee} from 'vue3-marquee'
import type {NotificationType} from 'naive-ui'
import {NIcon, useMessage} from 'naive-ui'
import {LogInOutline as LogInIcon} from '@vicons/ionicons5'
import {useAuthStore} from '@/store'
import {useBasicLayout} from '@/hooks/useBasicLayout'
import {fetchAddOrder, fetchPayment, fetchProDetail, fetchProList, fetchwxPayment} from '@/api'
import {debounce} from 'vue-debounce';

const props = defineProps<Props>()

const emit = defineEmits<Emit>()
const showDetail = ref<boolean>(false)
const {isMobile} = useBasicLayout()
const productDetail = ref<any>(null)
const alipay = ref<any>('')
const modalTitle = ref<any>('会员列表')
const authStore = useAuthStore()
import {showLogin} from "@/hooks/LoginStatus";

const weixinImg = ref<any>('')
const weixinPayUrl = ref<string>('')
const notification = useNotification()

interface Props {
	showProModal: boolean,
	productList: Array<any>
}

interface Emit {
	(e: "update:showProModal", showProModal: boolean): void

	(e: "update:productList", productList: Array<any>): void
}

const meta = ref<string>('')
const message = useMessage()
const showModal = computed({
	get() {
		return props.showProModal
	},
	set(showProModal: boolean) {
		emit('update:showProModal', showProModal)
	},
})

const productList = computed({
	get() {
		return props.productList
	},

	set(productList: Array<any>) {
		emit('update:productList', productList)
	},
})

// const showWeiXinImg = ref<boolean>(false)
function cancelCallback() {
	showModal.value = false
	message.success('Cancel')
}

function submitCallback() {
	showModal.value = false
	message.success('Submit')
}
function copyUrl() {
	navigator.clipboard.writeText(weixinPayUrl.value)
		.then(() => {
			message.success("复制成功，请到微信内后点击链接")
		})
		.catch(err => {
			message.error("请尝试长按手动复制")
		});
}

function changeShowDetail() {
	showDetail.value = !showDetail.value
	if (showDetail.value) {
		// 设置微信按钮可点击
		modalTitle.value = '会员详情'
	} else {
		modalTitle.value = '会员列表'
	}
}

function notify(type: NotificationType) {
	notification[type]({
		content: '提示',
		meta: meta.value,
		duration: 2500,
		keepAliveOnHover: true,
	})
}

// 查询商品详情
function gotoDetail(id: { id: string }) {
	const params = {id: id.toString()}
	fetchProDetail(params).then((res) => {
		if (res.status === 200) {
			productDetail.value = res.data.data
			changeShowDetail()
		}
	}).catch((error: any) => {
		if (error.response) {
			// 请求发生错误，获取状态码
			if (error.response.status === 401) {
				meta.value = '当前已经掉线了，请重新登录'
				if (showLogin.value) {
					showModal.value = false
					notify('warning')
				}
				authStore.removeToken()
				Cookies.remove('token')
				showLogin.value = true
			}
		}
	})
}

const debouncedClick = debounce(function (key: number, proId: string) {
	toPayment(key, proId);
}, 1000)


// 去支付
function toPayment(key: number, proId: string) {
	if (key === 1) {
		let tempOrderId = ''
		const paramOrder = {
			productId: proId,
			payment: 'aliPay',
		}
		fetchAddOrder(paramOrder).then((res) => {
			if (res.data.data === 200) {
				tempOrderId = res.data.msg
				if (tempOrderId) {
					const paramPay = {
						device: isMobile.value ? 'app' : 'pc',
						orderId: tempOrderId,
					}
					fetchPayment(paramPay).then((res) => {
						if (res.status === 200) {
							alipay.value = res.data
							document.write(alipay.value)
							const form = document.forms[0]
							form.submit()
						} else {
							message.error('支付失败')
						}
					})
				}
			}
		})
	} else if (key === 2) {
		let tempOrderId = ''
		weixinImg.value = ''
		const paramOrder = {
			productId: proId,
			payment: 'wxPay',
		}
		fetchAddOrder(paramOrder).then((res) => {
			if (res.data.data === 200) {
				tempOrderId = res.data.msg
				if (tempOrderId) {
					const paramPay = {
						device: 'pc',
						orderId: tempOrderId,
					}
					fetchwxPayment(paramPay).then((res) => {
						if (res.data.code == 200) {
							let payParam = JSON.parse(res.data.msg);
							console.log(payParam)
							weixinImg.value = payParam.imgUrl
							weixinPayUrl.value = payParam.wxPayUrl
						}else{
							message.error(res.data.msg)
						}
					})
				}
			}
		})
	}
}
</script>

<template>
	<n-modal
		v-model:show="showModal"
		preset="dialog"
		:title="modalTitle"
		style="width: 80%; max-width: 900px;"
		@positive-click="submitCallback" @negative-click="cancelCallback"
	>
		<!--		<Vue3Marquee bgcolor="#F2F6FC" style="font-size: 10px">-->
		<!--			<n-gradient-text type="success">-->
		<!--				🔈818活动来袭~ 今日至次日0点，会员续费和升级将得到为双倍时长！不限次，可按需订阅时长，不要错过~-->
		<!--			</n-gradient-text>-->
		<!--		</Vue3Marquee>-->
		<div v-if="!showDetail">
			<div v-if="!isMobile" class="outer-box">
				<n-grid :x-gap="20" :y-gap="20" :cols="3">
					<n-grid-item
						v-for="(item, index) in productList"
						:key="index"
					>
						<div class="pro-box">
							<div class="img-box">
								<img :src="item.picture">
							</div>
							<div class="pro-info-box flex">
								<div class="pro-info">
									{{ item.productName }}
								</div>
								<div class="pro-price">
									￥{{ item.price }}
									<span>
										<svg v-if="item.oldPrice!=null || item.oldPrice>item.price" t="1699193942674" class="icon"
												 viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9105" width="16"
												 height="16"
										><path d="M508.8 970.048l188.8-316.8-156.288 84.672V64h-64v674.048L320 653.248l188.8 316.8z"
													 fill="#32E48E" p-id="9106"
										></path></svg>
									</span>
								</div>
								<div class="pro-btn">
									<n-button strong secondary round type="success" @click="gotoDetail(item.productId)">
										详情
									</n-button>
								</div>
							</div>
						</div>
					</n-grid-item>
				</n-grid>
			</div>
			<div v-if="isMobile" class="outer-box-mobile">
				<n-grid :x-gap="12" :y-gap="12" :cols="1">
					<n-grid-item
						v-for="(item, index) in productList"
						:key="index"
					>
						<div class="pro-box-mobile">
							<div class="img-box">
								<img :src="item.picture">
							</div>
							<div class="pro-info-box-mobile flex">
								<div class="pro-info-mobile">
									{{ item.productName }}
								</div>
								<div class="pro-price-mobile">
									<!--                  <span v-if="item.productId === '8913aa51-9eb1-4031-aa73-8bf1112cbb88'" style="text-decoration:line-through;text-align: right;color: #8c959f">-->
									<!--                    ￥19.9-->
									<!--                  </span>-->
									<!--                  <span v-if="item.productId === '1a13aa51-9eb1-4031-aa73-8bf1112cbb85'" style="text-decoration:line-through;text-align: right;color: #8c959f">-->
									<!--                    ￥49.9-->
									<!--                  </span>-->
									<!--                  <span v-if="item.productId === '5edaa51-9eb1-4531-aa73-8bf1112cbb54'" style="text-decoration:line-through;text-align: right;color: #8c959f">-->
									<!--                    ￥188.0-->
									<!--                  </span>-->
									￥{{ item.price }}
								</div>
								<div class="pro-btn">
									<n-button strong secondary round type="success" size="tiny" @click="gotoDetail(item.productId)">
										查看详情
									</n-button>
								</div>
							</div>
						</div>
					</n-grid-item>
				</n-grid>
			</div>
		</div>
		<div v-else>
			<div class="detail-box" :class="[isMobile ? '' : 'flex']">
				<div>
					<img :src="productDetail.picture" :class="isMobile ? 'mobile-img' : 'pc-img'">
					<div v-if="!isMobile" class="span-box">
						<div>
							<svg
								t="1687191359249" class="icon" style="display: inline-block" viewBox="0 0 1024 1024" version="1.1"
								xmlns="http://www.w3.org/2000/svg" p-id="62598" width="25" height="25"
							>
								<path
									d="M512.17 97.29h-0.23C282.91 97.29 97.23 283 97.23 512s185.68 414.71 414.71 414.71c229 0.06 414.76-185.56 414.83-414.6S741.21 97.36 512.17 97.29z m-0.23 760.3c-190.86 0-345.59-154.73-345.59-345.59s154.73-345.59 345.59-345.59S857.53 321.14 857.53 512 702.81 857.59 511.94 857.59z"
									fill="#0e7a0d" p-id="62599"
								/>
								<path
									d="M656.4 379.83L465.07 571.16l-96.78-96.73a36.2 36.2 0 1 0-51.87 50.52c0.21 0.22 0.43 0.44 0.65 0.65L432.43 641a46.08 46.08 0 0 0 65.2 0l209.99-210a36.21 36.21 0 0 0-51.22-51.18z"
									fill="#0e7a0d" p-id="62600"
								/>
							</svg>
							Plus付费账号接入
						</div>
						<div>
							<svg
								t="1687191359249" class="icon" style="display: inline-block" viewBox="0 0 1024 1024" version="1.1"
								xmlns="http://www.w3.org/2000/svg" p-id="62598" width="25" height="25"
							>
								<path
									d="M512.17 97.29h-0.23C282.91 97.29 97.23 283 97.23 512s185.68 414.71 414.71 414.71c229 0.06 414.76-185.56 414.83-414.6S741.21 97.36 512.17 97.29z m-0.23 760.3c-190.86 0-345.59-154.73-345.59-345.59s154.73-345.59 345.59-345.59S857.53 321.14 857.53 512 702.81 857.59 511.94 857.59z"
									fill="#0e7a0d" p-id="62599"
								/>
								<path
									d="M656.4 379.83L465.07 571.16l-96.78-96.73a36.2 36.2 0 1 0-51.87 50.52c0.21 0.22 0.43 0.44 0.65 0.65L432.43 641a46.08 46.08 0 0 0 65.2 0l209.99-210a36.21 36.21 0 0 0-51.22-51.18z"
									fill="#0e7a0d" p-id="62600"
								/>
							</svg>
							AI绘画
						</div>
						<div>
							<svg
								t="1687191359249" class="icon" style="display: inline-block" viewBox="0 0 1024 1024" version="1.1"
								xmlns="http://www.w3.org/2000/svg" p-id="62598" width="25" height="25"
							>
								<path
									d="M512.17 97.29h-0.23C282.91 97.29 97.23 283 97.23 512s185.68 414.71 414.71 414.71c229 0.06 414.76-185.56 414.83-414.6S741.21 97.36 512.17 97.29z m-0.23 760.3c-190.86 0-345.59-154.73-345.59-345.59s154.73-345.59 345.59-345.59S857.53 321.14 857.53 512 702.81 857.59 511.94 857.59z"
									fill="#0e7a0d" p-id="62599"
								/>
								<path
									d="M656.4 379.83L465.07 571.16l-96.78-96.73a36.2 36.2 0 1 0-51.87 50.52c0.21 0.22 0.43 0.44 0.65 0.65L432.43 641a46.08 46.08 0 0 0 65.2 0l209.99-210a36.21 36.21 0 0 0-51.22-51.18z"
									fill="#0e7a0d" p-id="62600"
								/>
							</svg>
							不限设备
						</div>
						<div>
							<svg
								t="1687191359249" class="icon" style="display: inline-block" viewBox="0 0 1024 1024" version="1.1"
								xmlns="http://www.w3.org/2000/svg" p-id="62598" width="25" height="25"
							>
								<path
									d="M512.17 97.29h-0.23C282.91 97.29 97.23 283 97.23 512s185.68 414.71 414.71 414.71c229 0.06 414.76-185.56 414.83-414.6S741.21 97.36 512.17 97.29z m-0.23 760.3c-190.86 0-345.59-154.73-345.59-345.59s154.73-345.59 345.59-345.59S857.53 321.14 857.53 512 702.81 857.59 511.94 857.59z"
									fill="#0e7a0d" p-id="62599"
								/>
								<path
									d="M656.4 379.83L465.07 571.16l-96.78-96.73a36.2 36.2 0 1 0-51.87 50.52c0.21 0.22 0.43 0.44 0.65 0.65L432.43 641a46.08 46.08 0 0 0 65.2 0l209.99-210a36.21 36.21 0 0 0-51.22-51.18z"
									fill="#0e7a0d" p-id="62600"
								/>
							</svg>
							稳定可靠
						</div>
					</div>
				</div>
				<div class="detail-content" :class="isMobile ? 'mobile-content' : ''">
					<n-descriptions label-placement="left" bordered label-style="width:20%;min-width:5em">
						<n-descriptions-item label="名称" :span="5">
							<b style="font-weight: bold">{{ productDetail.productName }}</b>
						</n-descriptions-item>
						<n-descriptions-item label="价格" :span="5">
							<div style="color:red">

								<span style="font-size: 15px;font-weight: bold">￥{{ productDetail.price }}</span>

								&nbsp;&nbsp;
								<span v-if="productDetail.oldPrice != null && productDetail.oldPrice != productDetail.price"
											style="text-decoration:line-through;color: #8c959f;font-size: 13px"
								>￥{{ productDetail.oldPrice }}</span>
							</div>
						</n-descriptions-item>
						<n-descriptions-item label="描述">
							{{ productDetail.productDescription }}
						</n-descriptions-item>
					</n-descriptions>

					<div class="detail-btn">
						<n-button class="buy-btn" @click="debouncedClick(1, productDetail.productId)">
							<svg
								t="1686574480109" class="icon" viewBox="0 0 1024 1024" version="1.1"
								xmlns="http://www.w3.org/2000/svg" p-id="3356" width="32" height="32"
							>
								<path
									d="M896 650.666667l-247.04-83.072s18.986667-28.416 39.253333-84.138667c20.266667-55.722667 23.168-86.314667 23.168-86.314667l-159.914666-1.28V341.162667l193.706666-1.365334V301.226667h-193.706666V213.333333H456.533333v87.893334H275.882667v38.613333l180.693333-1.28v58.581333H311.637333v30.592h298.325334s-3.285333 24.832-14.72 55.722667a1254.485333 1254.485333 0 0 1-23.210667 57.941333s-140.074667-49.024-213.888-49.024-163.584 29.653333-172.288 115.712c-8.661333 86.016 41.813333 132.608 112.938667 149.76 71.125333 17.237333 136.789333-0.170667 193.962666-28.16 57.173333-27.946667 113.28-91.477333 113.28-91.477333l287.914667 139.818667A142.08 142.08 0 0 1 753.792 896H270.208A142.08 142.08 0 0 1 128 754.048V270.208A142.08 142.08 0 0 1 269.952 128h483.84A142.08 142.08 0 0 1 896 269.952v380.714667z m-360.064-48.128s-89.856 113.493333-195.754667 113.493333c-105.941333 0-128.170667-53.930667-128.170666-92.714667 0-38.741333 22.016-80.853333 112.170666-86.954666 90.069333-6.101333 211.84 66.176 211.84 66.176h-0.085333z"
									fill="#02A9F1" p-id="3357"
								/>
							</svg>
							&nbsp;&nbsp;支付宝
						</n-button>
						<n-popover trigger="click" style="width:10em">
							<template #trigger>
								<n-button class="buy-btn" @click="debouncedClick(2, productDetail.productId)">
									<svg
										t="1686576002230" class="icon" viewBox="0 0 1024 1024" version="1.1"
										xmlns="http://www.w3.org/2000/svg" p-id="14684" width="32" height="32"
									>
										<path
											d="M395.846 603.585c-3.921 1.98-7.936 2.925-12.81 2.925-10.9 0-19.791-5.85-24.764-14.625l-2.006-3.864-78.106-167.913c-0.956-1.98-0.956-3.865-0.956-5.845 0-7.83 5.928-13.68 13.863-13.68 2.965 0 5.928 0.944 8.893 2.924l91.965 64.43c6.884 3.864 14.82 6.79 23.708 6.79 4.972 0 9.85-0.945 14.822-2.926L861.71 282.479c-77.149-89.804-204.684-148.384-349.135-148.384-235.371 0-427.242 157.158-427.242 351.294 0 105.368 57.361 201.017 147.323 265.447 6.88 4.905 11.852 13.68 11.852 22.45 0 2.925-0.957 5.85-2.006 8.775-6.881 26.318-18.831 69.334-18.831 71.223-0.958 2.92-2.013 6.79-2.013 10.75 0 7.83 5.929 13.68 13.865 13.68 2.963 0 5.928-0.944 7.935-2.925l92.922-53.674c6.885-3.87 14.82-6.794 22.756-6.794 3.916 0 8.889 0.944 12.81 1.98 43.496 12.644 91.012 19.53 139.48 19.53 235.372 0 427.24-157.158 427.24-351.294 0-58.58-17.78-114.143-48.467-163.003l-491.39 280.07-2.963 1.98z"
											fill="#09BB07" p-id="14685"
										/>
									</svg>
									&nbsp;&nbsp;微信
								</n-button>
							</template>
							<img :src="weixinImg" @click="debouncedClick(2, productDetail.productId)">
							<template #footer>
								使用微信扫码支付
							</template>
						</n-popover>
					</div>

					<n-tag :bordered="false" v-if="weixinPayUrl"  size="small">
						手机微信支付，请复制此支付链接在微信打开以完成支付，点击即可复制
					</n-tag>
					<n-tag :bordered="false" @click="copyUrl" v-if="weixinPayUrl" type="success" size="small">
						{{weixinPayUrl}}
					</n-tag>
				</div>
			</div>
			<div class="back-btn">
				<n-button text @click="changeShowDetail()">
					<template #icon>
						<NIcon>
							<LogInIcon/>
						</NIcon>
					</template>
					返回列表
				</n-button>
			</div>
		</div>
	</n-modal>
</template>

<style scoped>
.outer-box {
	padding: 2%;
	background-color: #F6F8FF;
	overflow: auto;
	max-height: 45em;
}

.outer-box-mobile {
	padding: 2%;
	background-color: #F6F8FF;
	overflow: auto;
	max-height: 25em;
}

.pro-box {
	height: 18em;
	background-color: #ffffff;
	border: 1px solid #e8e8e8;
	border-radius: 5px;
	box-shadow: 0 3px 6px #0000001f;
	text-align: center;

}

.img-box img {
	height: 100%;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.pro-box .img-box {
	height: 60%;
	width: 100%;
}

.pro-box-mobile .img-box {
	height: 60%;
	width: 100%;
}

.pro-info-box {
	background: #F2F6FC;
	padding: 0.5em;
	color: #606266;
	height: 40%;
	flex-wrap: wrap;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.pro-info-box-mobile {
	background: #F2F6FC;
	padding: 0.5em;
	color: #606266;
	height: 40%;
	flex-wrap: wrap;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.pro-price {
	color: red;
	text-align: right;
	border-top-left-radius: 0.5em;
	border-top-right-radius: 0.5em;
	width: 25%;
	display: flex;
	justify-content: space-between;
}

.pro-price-mobile {
	color: red;
	text-align: right;
	width: 25%;
}

.pro-info {
	text-align: left;
	font-weight: 600;
	font-size: 1em;
	width: 75%;
}

.pro-info-mobile {
	text-align: left;
	font-weight: 600;
	font-size: 0.1em;
	width: 75%;
}

.pro-btn {
	flex-basis: 100%;
	text-align: center;
}

.pro-box-mobile {
	height: 15em;
	/* background-color: #F6F8FF; */
	border: 1px solid #e8e8e8;
	border-radius: 5px;
	box-shadow: 0 3px 1px #0000001f;
}

/* 详情页样式 */
.back-btn {
	width: 100%;
	text-align: right;
}

.detail-box {
	padding: 2%;
	background: rgba(250, 250, 252, 1);
}

.detail-box img {
	width: 50%;
}

.detail-content {
	border-left: 1px solid #DCDFE6;
	margin-left: 1em;
	padding-left: 1em;
	flex: 1;
}

.detail-btn {
	height: 5em;
	display: flex;
	justify-content: center;
}

.buy-btn {
	width: 48%;
	margin: 2em 0.5em 0 0.5em;
}

.pc-img {
	width: 100% !important;
	height: 15em;
}

.mobile-img {
	width: 100% !important;
}

.span-box {
	margin-top: 1em;
	height: 27%;
	display: flex;
	padding: 0%;
	justify-content: space-between;
	align-items: center;
}

.mobile-content {
	border: none !important;
	margin-left: 0 !important;
	margin-top: 0.5em;
	padding-left: 0 !important;
}
</style>
