<script setup lang='ts'>
import { defineAsyncComponent, ref } from 'vue'
import { HoverButton, SvgIcon, UserAvatar } from '@/components/common'

const Setting = defineAsyncComponent(() => import('@/components/common/Setting/index.vue'))

const show = ref(false)
</script>

<template>
  <footer class="flex items-center justify-between min-w-0 p-4 overflow-hidden border-t dark:border-neutral-800">
    <div class="flex-1 flex-shrink-0 overflow-hidden">
      <UserAvatar />
    </div>
    <HoverButton :tooltip="$t('setting.setting')" @click="show = true">
      <span class="text-xl text-[#4f555e] dark:text-white">
        <svg t="1699543001483" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5149" width="28" height="28"><path d="M512 550.09a238.1 238.1 0 0 1-168.36-406.45 238.1 238.1 0 0 1 336.72 336.72A236.53 236.53 0 0 1 512 550.09z m0-428.18c-104.82 0-190.09 85.27-190.09 190.09S407.18 502.09 512 502.09 702.09 416.82 702.09 312 616.82 121.91 512 121.91z" p-id="5150"></path><path d="M112 950.09H64a447.66 447.66 0 0 1 448-448v48c-220.56 0-400 179.44-400 400z m644.69-246.67a52.8 52.8 0 1 1-45.8 26.41 52.68 52.68 0 0 1 45.8-26.41m0-38A90.81 90.81 0 1 0 802 677.6a90.77 90.77 0 0 0-45.31-12.18z" p-id="5151"></path><path d="M688.67 604.46l4.3 7.46 13.48 23.34 26.47-5a128.19 128.19 0 0 1 47.4 0l26.47 5 13.47-23.33 4.28-7.42 29.52 17-4.28 7.42-13.47 23.32 17.56 20.44a127.36 127.36 0 0 1 23.66 41.06l8.91 25.43H922v34.09h-35.48l-8.94 25.37a129.83 129.83 0 0 1-23.75 41.14l-17.51 20.43 13.45 23.3 4.29 7.43-29.52 17-4.31-7.45-13.47-23.34-26.48 5a129 129 0 0 1-23.84 2.23 127.26 127.26 0 0 1-23.55-2.2l-26.47-5L693 900.57l-4.33 7.43-29.53-17 4.29-7.42 13.46-23.32-17.55-20.44a127.47 127.47 0 0 1-23.66-41.06l-8.92-25.43h-35.55v-34.15h35.48l8.94-25.38a129.93 129.93 0 0 1 23.75-41.13l17.51-20.43-13.45-23.3-4.3-7.43 29.53-17m131.79-42.11a26.78 26.78 0 0 0-23.22 13.4l-9.89 17.13a166 166 0 0 0-61.47 0L716 575.75a26.76 26.76 0 0 0-36.59-9.8l-49 28.26a26.78 26.78 0 0 0-9.8 36.59l9.9 17.14a167.74 167.74 0 0 0-30.74 53.24H580A26.78 26.78 0 0 0 553.21 728v56.52A26.78 26.78 0 0 0 580 811.27h19.83a166 166 0 0 0 30.7 53.25l-9.89 17.13a26.78 26.78 0 0 0 9.8 36.59l49 28.26a26.78 26.78 0 0 0 36.56-9.81l9.89-17.12a166 166 0 0 0 61.47 0l9.91 17.16a26.78 26.78 0 0 0 36.59 9.81l48.95-28.26a26.78 26.78 0 0 0 9.8-36.59l-9.9-17.14a167.74 167.74 0 0 0 30.74-53.24h19.79A26.78 26.78 0 0 0 960 784.48V728a26.78 26.78 0 0 0-26.79-26.78h-19.82a165.94 165.94 0 0 0-30.7-53.25l9.89-17.13a26.78 26.78 0 0 0-9.8-36.59L833.83 566a26.65 26.65 0 0 0-13.37-3.6z" p-id="5152"></path></svg>
      </span>
    </HoverButton>

    <Setting v-if="show" v-model:visible="show" />
  </footer>
</template>
