import type { AxiosProgressEvent, GenericAbortSignal } from 'axios'
import axios from 'axios'
import Cookies from 'js-cookie'
import { post } from '@/utils/request'
import {v4 as uuidv4} from "uuid";

export function fetchChatAPI<T = any>(
  prompt: string,
  options?: { conversationId?: string; parentMessageId?: string },
  signal?: GenericAbortSignal,
) {
  return post<T>({
    url: '/generate',
    data: {
      prompt,
      options,
      userId: window.location.hash,
    },
    signal,
  })
}

export function fetchChatConfig<T = any>() {
  return post<T>({
    url: '/config',
  })
}

export async function fetchChatAPIProcess<T = any>(
  params: {
    prompt: string
    network?: boolean
    usingContext: boolean
    options?: { conversationId?: string; parentMessageId?: string }
    signal?: GenericAbortSignal
    onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
    uuid: number
    index?: number
    system?: string
    dialogNumber?: number
    temperature?: number
    topP?: number
		modelValue?: string,
  },
) {
  const token = Cookies.get('token')
  const config = {
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  }
	let hash = window.location.hash;
	if(hash.length<=10){
		hash += uuidv4()
	}


  return axios.post('/api/toConversation',
    {
      prompt: params.prompt,
			sessionId: hash,
      network: !!params.network,
      withoutContext: !params.usingContext,
      system: params.system,
      dialogNumber: params.dialogNumber,
      temperature: params.temperature,
      topP: params.topP,
			modelValue: params.modelValue,
    }, config)
}

export async function fetchChatAPIProcessV4<T = any>(
  params: {
    prompt: string
    network?: boolean
    usingContext: boolean
    options?: { conversationId?: string; parentMessageId?: string }
    signal?: GenericAbortSignal
    onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
    uuid: number
    index?: number
    system?: string
    dialogNumber?: number
    temperature?: number
    topP?: number
		modelValue?: string,

  },
) {
  const token = Cookies.get('token')
  const config = {
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  }
	let hash = window.location.hash;
	if(hash.length<=10){
		hash += uuidv4()
	}

  return axios.post('/api/toConversationV4',
    {
      prompt: params.prompt,
			sessionId: hash,
      network: !!params.network,
      withoutContext: !params.usingContext,
      system: params.system,
      dialogNumber: params.dialogNumber,
      temperature: params.temperature,
      topP: params.topP,
			modelValue: params.modelValue,
    }, config).then((response) => {
    const result = response.data
    if (result.code === 200) {
      // if (result.data === 10010)
      // // TODO此处需要弹个框，弹出会员充值界面 然后需要终止回答框的输出
    }
  })
}

export function fetchSession<T>() {
  return post<T>({
    url: '/session',
  })
}

export function fetchVerify<T>(token: string) {
  return post<T>({
    url: '/verify',
    data: { token },
  })
}

// 查询角色列表
export async function fetchRoleList<T = any>(
  params: {
    currentPage: number
    pageSize: number
    queryString: string
  },
) {
  const token = Cookies.get('token')
  const config = {
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  }
  return axios.post('/api/getRoleList',
    {
      currentPage: params.currentPage,
      pageSize: params.pageSize,
      queryString: params.queryString,
    }, config)
}
// 查询商品列表
export async function fetchProList<T = any>(
  params: {
    currentPage: number
    pageSize: number
    queryString: string
  },
) {
  const token = Cookies.get('token')
  const config = {
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  }
  return axios.post('/api/product/findProductList',
    {
      currentPage: params.currentPage,
      pageSize: params.pageSize,
      queryString: params.queryString,
    }, config)
}
// 生成订单
export async function fetchAddOrder<T = any>(
  params: {
    productId: string // 商品Id
    payment: string // 支付渠道
  },
) {
  const token = Cookies.get('token')
  const config = {
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  }
  return axios.post('/api/order/createAliPayOrder',
    {
      productId: params.productId,
      payment: params.payment,
    }, config)
}
// 查询商品详情
export async function fetchProDetail<T = any>(
  params: {
    id: string
  },
) {
  const token = Cookies.get('token')
  const config = {
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  }
  return axios.get(`/api/product/getProductDetail/${params.id}`, config)
}
// 支付宝跳转支付
export async function fetchPayment<T = any>(
  params: {
    device: string
    orderId: string
  },
) {
  const token = Cookies.get('token')
  const config = {
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  }
  return axios.post('/api/pay/aliPayment', { device: params.device, orderId: params.orderId }, config)
}

// 支付宝跳转支付
export async function fetchwxPayment<T = any>(
  params: {
    device: string
    orderId: string
  },
) {
  const token = Cookies.get('token')
  const config = {
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  }
  return axios.post('/api/pay/wxPayment', { device: params.device, orderId: params.orderId }, config)
}

// 获取公开的模板
export async function getPublicTemplate<T = any>(
	params: {
		currentPage: number
		pageSize: number
		queryString: string
	},
) {
	const token = Cookies.get('token')
	const config = {
		withCredentials: true,
		headers: {
			'content-type': 'application/json',
			'Authorization': `Bearer ${token}`,
		},
	}
	return axios.post('/api/artQR/getPublicTemplate',
		{
			currentPage: params.currentPage,
			pageSize: params.pageSize,
			queryString: params.queryString,
		}, config)
}

// 获取模型
export async function getPublicModelList<T = any>(
	params: {
		currentPage: number
		pageSize: number
		queryString: string
	},
) {
	const token = Cookies.get('token')
	const config = {
		withCredentials: true,
		headers: {
			'content-type': 'application/json',
			'Authorization': `Bearer ${token}`,
		},
	}
	return axios.post('/api/artQR/getPublicModel',
		{
			currentPage: params.currentPage,
			pageSize: params.pageSize,
			queryString: params.queryString,
		}, config)
}

// 获取用户公开作品图像列表
export async function getPublicPictureList<T = any>(
  params: {
    currentPage: number
    pageSize: number
    queryString: string
  },
) {
  const token = Cookies.get('token')
  const config = {
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  }
  return axios.post('/api/mjDraw/getPublicPictureList',
    {
      currentPage: params.currentPage,
		        pageSize: params.pageSize,
		        queryString: params.queryString,
    }, config)
}

// 获取用户私有作品图像列表
export async function getPrivatePictureList<T = any>(
	params: {
		currentPage: number
		pageSize: number
		queryString: string
	},
) {
	const token = Cookies.get('token')
	const config = {
		withCredentials: true,
		headers: {
			'content-type': 'application/json',
			'Authorization': `Bearer ${token}`,
		},
	}
	return axios.post('/api/mjDraw/getSelfPictureList',
		{
			currentPage: params.currentPage,
			pageSize: params.pageSize,
			queryString: params.queryString,
		}, config)
}


// 获取用户私有作品图像列表
export async function getTaskList<T = any>(
	params: {
		currentPage: number
		pageSize: number
		queryString: string
	},
) {
	const token = Cookies.get('token')
	const config = {
		withCredentials: true,
		headers: {
			'content-type': 'application/json',
			'Authorization': `Bearer ${token}`,
		},
	}
	return axios.post('/api/artQR/getTaskList',
		{
			currentPage: params.currentPage,
			pageSize: params.pageSize,
			queryString: params.queryString,
		}, config)
}
// 获取回复语音链接
export async function getAudio<T = any>(
	params: {
		prompt: string
		userId: string
	},
) {
	const token = Cookies.get('token')
	const config = {
		withCredentials: true,
		headers: {
			'content-type': 'application/json',
			'Authorization': `Bearer ${token}`,
		},
	}
	return axios.post('/api/textToSpeech',
		{
			prompt: params.prompt,
			userId: params.userId,
		}, config)
}
