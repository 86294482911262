<script lang="ts" setup>
//选择角色
import {useBasicLayout} from "@/hooks/useBasicLayout";

const emit = defineEmits(['chatByRole', 'handleRunConversation']);

function toChooseRole() {
	emit('chatByRole')
}

const {isMobile} = useBasicLayout()

function handleRunConversation(str: string) {
	emit("handleRunConversation", str)
}
</script>
<template>
	<!--PC端	-->
	<div v-if="!isMobile" class="main-box dark:bg-[#101014] flex items-center flex-col  text-center">
		<n-grid x-gap="12" :cols="1">
			<n-gi>
				<n-h1>
					<n-gradient-text
						style="font-weight: 600;"
						:size="35"
						gradient="linear-gradient(90deg, rgb(26 83 89) 70%, lime 30%, lightblue)"
					>
						打工人 AI
					</n-gradient-text>
					<span class="icon-box">PLUS</span>
				</n-h1>
			</n-gi>
		</n-grid>
		<n-grid x-gap="12" :cols="3">
			<n-gi style="height: 20em">
				<div class="top-box">
					<div class="dark:bg-[#101014] items-center text-center flex justify-center">
						<svg t="1689695347157" class="icon dark:bg-[#101014]" viewBox="0 0 1024 1024" version="1.1"
								 xmlns="http://www.w3.org/2000/svg" p-id="895" width="32" height="32"
						>
							<path
								d="M512 79.47c-246.17 0-445.73 191.76-445.73 428.31 0 89.79 28.75 173.12 77.89 241.96 7.86 11.01-72.84 168.34-46.15 191.01 3.35 2.63 8.52 3.78 15.04 3.78 47.57 0 167.35-61.46 183.17-61.45 0.55 0 0.98 0.07 1.27 0.23 63.64 33.64 136.75 52.77 214.51 52.77 246.17 0 445.73-191.76 445.73-428.31 0-236.54-199.56-428.3-445.73-428.3z m272.92 687.18c-35.33 33.95-76.51 60.62-122.38 79.26-47.62 19.36-98.27 29.17-150.53 29.17-33.15 0-66.04-4.02-97.76-11.95-30.72-7.68-60.41-19.03-88.25-33.75-9.15-4.84-19.16-7.29-29.74-7.3h-0.03c-18.01 0-34.41 6.84-73.98 23.36-16.58 6.92-34.9 14.57-52.71 21.32 4.55-12.69 9.47-25.59 14.01-37.48 6.08-15.93 11.33-29.69 15.14-41.32 2.63-8.03 4.37-14.46 5.47-20.22 1.07-5.6 1.58-10.79 1.56-15.86-0.08-17.41-6.51-30.04-11.89-37.58-43.53-60.99-66.54-132.4-66.54-206.52 0-49.31 10.05-97.13 29.86-142.14 19.22-43.67 46.8-82.95 81.96-116.73 35.33-33.95 76.51-60.62 122.38-79.26 47.62-19.36 98.27-29.17 150.53-29.17s102.91 9.81 150.53 29.17c45.88 18.65 87.05 45.31 122.38 79.26 35.16 33.79 62.74 73.06 81.96 116.73 19.81 45.01 29.86 92.83 29.86 142.14s-10.05 97.13-29.86 142.14c-19.24 43.67-46.81 82.94-81.97 116.73z"
								p-id="896"
							></path>
							<path d="M288.735269 541.60748a52 52 0 1 0 85.477139-59.242373 52 52 0 1 0-85.477139 59.242373Z"
										p-id="897"
							></path>
							<path d="M512 512m-52 0a52 52 0 1 0 104 0 52 52 0 1 0-104 0Z" p-id="898"></path>
							<path d="M692.52 512m-52 0a52 52 0 1 0 104 0 52 52 0 1 0-104 0Z" p-id="899"></path>
						</svg>
					</div>
					<div>
						使用示例
					</div>
				</div>
				<div class=" dark:bg-[#101014] tagBox bg-gray-100  items-center text-center flex justify-center">
					<n-button quaternary class="shili" @click="handleRunConversation('用java实现一个冒泡排序，并附带详细的注释')">
						用java实现一个冒泡排序 _>
					</n-button>
				</div>
				<div class="dark:bg-[#101014] tagBox bg-gray-100 items-center text-center flex justify-center">
					<n-button quaternary class="shili"
										@click="handleRunConversation('vue如何实现一个父子组件传递，希望可以举个实例并有详细的注释以帮助理解')"
					>
						vue3如何实现一个父子组件传参 _>
					</n-button>
				</div>
				<div class="dark:bg-[#101014] tagBox bg-gray-100 items-center text-center flex justify-center">
<!--					<n-tooltip trigger="hover">-->
<!--						<template #trigger>-->
<!--							<n-button quaternary class="shili" @click="handleRunConversation('写一篇人类登陆月球成功的演讲稿')">-->
<!--						      写一篇人类第一次登陆月球的演讲稿 ->-->
<!--					        </n-button>-->
<!--						</template>-->
<!--						写一篇人类第一次登陆月球的演讲稿 ->-->
<!--					</n-tooltip>-->
					<n-button quaternary class="shili"
										@click="handleRunConversation('写一篇人类登陆月球成功的演讲稿')"
					>
						写一篇人类登陆月球成功的演讲稿 _>
					</n-button>
				</div>
			</n-gi>
			<n-gi>
				<div class="top-box">
					<div class="dark:bg-[#101014] items-center text-center flex justify-center">
						<svg t="1689696374711" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
								 p-id="2072" width="32" height="32"
						>
							<path
								d="M832.65 710.71c-23.06 0-44.64 6.28-63.16 17.2l-104.1-104.1c23.26-31.74 37-70.89 37-113.25 0-44.18-14.95-84.87-40.05-117.3l4.8 4.8 141.63-141.63c14.71 8.17 31.64 12.83 49.66 12.83 56.58 0 102.46-45.87 102.46-102.46S915.01 64.33 858.43 64.33c-56.58 0-102.46 45.87-102.46 102.46 0 16.35 3.84 31.8 10.65 45.51L624 354.92l3.86 3.86c-32.43-25.1-73.12-40.05-117.3-40.05-42.84 0-82.39 14.04-114.31 37.77l0.19-0.19-107.13-107.13c7.76-14.43 12.17-30.93 12.17-48.46 0-56.58-45.87-102.46-102.46-102.46S96.56 144.13 96.56 200.72s45.87 102.46 102.46 102.46c16.84 0 32.73-4.06 46.75-11.27L353.3 399.44l3.2-3.2c-23.73 31.93-37.77 71.48-37.77 114.32 0 42.36 13.74 81.51 37 113.25l-85.98 85.98c-21.92-19.27-50.67-30.96-82.15-30.96-68.75 0-124.48 55.73-124.48 124.48s55.73 124.48 124.48 124.48 124.48-55.73 124.48-124.48c0-14.83-2.6-29.04-7.35-42.23l94.38-94.38c31.42 22.46 69.89 35.69 111.46 35.69 41.57 0 80.04-13.22 111.46-35.69L726.1 770.79c-11.38 18.79-17.93 40.83-17.93 64.4 0 68.75 55.73 124.48 124.48 124.48s124.48-55.73 124.48-124.48-55.73-124.48-124.48-124.48z m25.78-585.38c22.86 0 41.46 18.6 41.46 41.46s-18.6 41.46-41.46 41.46-41.46-18.6-41.46-41.46 18.6-41.46 41.46-41.46z m-700.87 75.39c0-22.86 18.6-41.46 41.46-41.46s41.46 18.6 41.46 41.46-18.6 41.46-41.46 41.46-41.46-18.6-41.46-41.46z m30.04 666.07c-35 0-63.48-28.48-63.48-63.48s28.48-63.48 63.48-63.48 63.48 28.48 63.48 63.48-28.48 63.48-63.48 63.48z m230.45-263.72c-24.71-24.71-38.32-57.56-38.32-92.51s13.61-67.8 38.32-92.51 57.56-38.32 92.51-38.32 67.8 13.61 92.51 38.32c24.71 24.71 38.32 57.56 38.32 92.51s-13.61 67.8-38.32 92.51c-24.71 24.71-57.56 38.32-92.51 38.32s-67.8-13.61-92.51-38.32z m414.6 295.6c-35 0-63.48-28.48-63.48-63.48s28.48-63.48 63.48-63.48 63.48 28.48 63.48 63.48-28.48 63.48-63.48 63.48z"
								p-id="2073"
							></path>
						</svg>
					</div>
					<div>
						能力
					</div>
				</div>
				<div class=" dark:bg-[#101014] tagBox bg-gray-100  items-center text-center flex justify-center">
					直观感受第四次工业革命
				</div>
				<div class="dark:bg-[#101014] tagBox bg-gray-100 items-center text-center flex justify-center">
					支持上下文对话
				</div>
				<div class="dark:bg-[#101014] tagBox bg-gray-100 items-center text-center flex justify-center">
					允许用户提供后续修正，接受或拒绝不正当的请求
				</div>
			</n-gi>
			<n-gi>
				<div class="top-box">
					<div class="dark:bg-[#101014] items-center text-center flex justify-center">
						<svg t="1689696303698" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
								 p-id="1802" width="32" height="32"
						>
							<path
								d="M512 66.27C265.83 66.27 66.27 265.83 66.27 512S265.83 957.73 512 957.73c246.17 0 445.73-199.56 445.73-445.73S758.17 66.27 512 66.27z m272.05 717.78c-35.35 35.35-76.5 63.1-122.31 82.48-47.39 20.05-97.77 30.21-149.74 30.21s-102.34-10.16-149.74-30.21c-45.81-19.37-86.96-47.12-122.31-82.48-35.35-35.35-63.1-76.5-82.48-122.31-20.04-47.39-30.21-97.77-30.21-149.74s10.16-102.34 30.21-149.74c19.37-45.81 47.12-86.96 82.48-122.31 35.35-35.35 76.5-63.1 122.31-82.48 47.39-20.05 97.77-30.21 149.74-30.21s102.34 10.16 149.74 30.21c45.81 19.37 86.96 47.12 122.31 82.48 35.35 35.35 63.1 76.5 82.48 122.31 20.04 47.39 30.21 97.77 30.21 149.74s-10.16 102.34-30.21 149.74c-19.38 45.8-47.13 86.95-82.48 122.31z"
								p-id="1803"
							></path>
							<path
								d="M512.05 353.68c-16.84 0-30.5 13.66-30.5 30.5v383.66c0 16.84 13.66 30.5 30.5 30.5s30.5-13.66 30.5-30.5V384.18c0-16.84-13.66-30.5-30.5-30.5z"
								p-id="1804"
							></path>
							<path d="M513 278.58m-41.58 0a41.58 41.58 0 1 0 83.16 0 41.58 41.58 0 1 0-83.16 0Z" p-id="1805"></path>
						</svg>
					</div>
					<div>
						局限性
					</div>
				</div>
				<div class=" dark:bg-[#101014] tagBox bg-gray-100  items-center text-center flex justify-center">
					可能偶尔会产生不正确的信息
				</div>
				<div class="dark:bg-[#101014] tagBox bg-gray-100 items-center text-center flex justify-center">
					可能产生有害的指令或带有偏见的内容
				</div>
				<div class="dark:bg-[#101014] tagBox bg-gray-100 items-center text-center flex justify-center">
					对2023年4月以后的事件了解有限
				</div>
			</n-gi>
		</n-grid>
		<n-grid x-gap="12" :cols="1">
			<n-gi>
				<n-button style="color: green" quaternary size="tiny" @click="toChooseRole">
					点我进入Prompt角色扮演 _>
				</n-button>
			</n-gi>
		</n-grid>
		<!--	<n-button @click="toChooseRole">选择角色</n-button>-->
	</div>
	<!--手机端-->
	<div v-if="isMobile" class="app-box space-style dark:bg-[#101014] flex items-center flex-col text-center">
		<n-space vertical >
			<n-layout>
				<n-layout-header>
					<n-grid x-gap="12" :cols="1">
						<n-gi class="title-style">
							<n-h1>
								<n-gradient-text
									style="font-weight: 600;"
									:size="35"
									gradient="linear-gradient(90deg, rgb(26 83 89) 70%, lime 30%, lightblue)"
								>
									打工人 AI
								</n-gradient-text>
								<span class="icon-box">PLUS</span>
							</n-h1>
						</n-gi>
					</n-grid>
				</n-layout-header>
				<n-layout-content content-style="padding: 24px;">
					<n-grid x-gap="12" :cols="1">
						<n-gi style="height: 20em">
							<div class="top-box">
								<div class="dark:bg-[#101014] items-center text-center flex justify-center">
									<svg t="1689695347157" class="icon dark:bg-[#101014]" viewBox="0 0 1024 1024" version="1.1"
											 xmlns="http://www.w3.org/2000/svg" p-id="895" width="32" height="32"
									>
										<path
											d="M512 79.47c-246.17 0-445.73 191.76-445.73 428.31 0 89.79 28.75 173.12 77.89 241.96 7.86 11.01-72.84 168.34-46.15 191.01 3.35 2.63 8.52 3.78 15.04 3.78 47.57 0 167.35-61.46 183.17-61.45 0.55 0 0.98 0.07 1.27 0.23 63.64 33.64 136.75 52.77 214.51 52.77 246.17 0 445.73-191.76 445.73-428.31 0-236.54-199.56-428.3-445.73-428.3z m272.92 687.18c-35.33 33.95-76.51 60.62-122.38 79.26-47.62 19.36-98.27 29.17-150.53 29.17-33.15 0-66.04-4.02-97.76-11.95-30.72-7.68-60.41-19.03-88.25-33.75-9.15-4.84-19.16-7.29-29.74-7.3h-0.03c-18.01 0-34.41 6.84-73.98 23.36-16.58 6.92-34.9 14.57-52.71 21.32 4.55-12.69 9.47-25.59 14.01-37.48 6.08-15.93 11.33-29.69 15.14-41.32 2.63-8.03 4.37-14.46 5.47-20.22 1.07-5.6 1.58-10.79 1.56-15.86-0.08-17.41-6.51-30.04-11.89-37.58-43.53-60.99-66.54-132.4-66.54-206.52 0-49.31 10.05-97.13 29.86-142.14 19.22-43.67 46.8-82.95 81.96-116.73 35.33-33.95 76.51-60.62 122.38-79.26 47.62-19.36 98.27-29.17 150.53-29.17s102.91 9.81 150.53 29.17c45.88 18.65 87.05 45.31 122.38 79.26 35.16 33.79 62.74 73.06 81.96 116.73 19.81 45.01 29.86 92.83 29.86 142.14s-10.05 97.13-29.86 142.14c-19.24 43.67-46.81 82.94-81.97 116.73z"
											p-id="896"
										></path>
										<path d="M288.735269 541.60748a52 52 0 1 0 85.477139-59.242373 52 52 0 1 0-85.477139 59.242373Z"
													p-id="897"
										></path>
										<path d="M512 512m-52 0a52 52 0 1 0 104 0 52 52 0 1 0-104 0Z" p-id="898"></path>
										<path d="M692.52 512m-52 0a52 52 0 1 0 104 0 52 52 0 1 0-104 0Z" p-id="899"></path>
									</svg>
								</div>
								<div>
									使用示例
								</div>
							</div>
							<div class=" dark:bg-[#101014] tagBox bg-gray-100  items-center text-center flex justify-center">
								<n-button quaternary class="shili"
													@click="handleRunConversation('用java实现一个冒泡排序，并希望有详细的注释以帮助理解')"
								>
									用java实现一个冒泡排序 _>
								</n-button>
							</div>
							<div class="dark:bg-[#101014] tagBox bg-gray-100 items-center text-center flex justify-center">
								<n-button quaternary class="shili"
													@click="handleRunConversation('vue如何实现一个父子组件传递，希望可以举个实例并有详细的注释以帮助理解')"
								>
									vue如何实现一个父子组件传参 _>
								</n-button>
							</div>
							<div class="dark:bg-[#101014] tagBox bg-gray-100 items-center text-center flex justify-center">
								<n-button quaternary class="shili" @click="handleRunConversation('写一篇人类登陆月球成功的演讲稿')">
									写一篇人类第一次登陆月球的演讲稿 _>
								</n-button>
							</div>
						</n-gi>
					</n-grid>
					<n-grid x-gap="12" :cols="1" class="button-style">
						<n-gi>
							<n-button style="color: green" quaternary size="tiny" @click="toChooseRole">
								点我进入Prompt角色扮演 _>
							</n-button>
						</n-gi>
					</n-grid>
				</n-layout-content>
			</n-layout>
		</n-space>
	</div>
</template>
<style scoped>
.main-box {
	padding: 3em 10em 0 10em;
	height: calc(100vh - 12em);
}

.app-box {
	padding: 2em 1em 5em 1em;
	height: calc(100vh - 12em);
}

.icon-box{
	 display: inline-block;
	 width: 5em;
	 margin: 0.5em;
	 height: 2.3em;
	 background: yellow;
	 color: #8b4513;
	 font-size: .3em;
	 padding: 0 0.5em;
	 border: 1px solid #ffcd2063;
	 font-weight: 800;
	 border-radius: 5px;
}

.title-style{
	margin-left: 2em;
}

.top-box {
	margin: 1em;
}

.shili {
	height: 100%;
	width: 100%;
	margin: 0;
}
.space-style{
	width: 100%;
	height: 100%;

}

.top-box div {
	margin: 0.8em;
}
.button-style{
	margin-top: 1em;
}


.tagBox {
	margin: 0.5em;
	height: 20%;
	border-radius: 5px;
	overflow: hidden; /*超出隐藏*/
	text-overflow: ellipsis; /*文本超出显示省略号*/
	border: 1px solid limegreen;
}

</style>
