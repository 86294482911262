<script lang="ts" setup>
import axios from 'axios'
import Cookies from 'js-cookie'
import {computed, reactive, ref} from 'vue'
import type {NotificationType} from 'naive-ui'
import {NButton, useMessage, useThemeVars} from 'naive-ui'
// import { useRouter } from 'vue-router'
import {changeColor} from 'seemly'
import type {Theme} from '@/store/modules/app/helper'
import {useAppStore, useAuthStore, useUserStore} from '@/store'
// import { getCurrentDate } from '@/utils/functions'
// import { t } from '@/locales'
const appStore = useAppStore()
const loginStatus = inject('showLogin');
const userStore = useUserStore()
const authStore = useAuthStore()
const message = useMessage()
const themeVars = useThemeVars()
let currentUserInfo = reactive({
	id: '',
	userId: '',
	userName: '',
	phone: '',
	userFlag: '',
	headImages: '',
	role: '',
})
const gpt3Percent = ref<number | number[]>(0)
const gpt4Percent = ref<number | number[]>(0)
const drawPercent = ref<number | number[]>(0)
const gpt3ProgressColor = ref<string>('')
const gpt4ProgressColor = ref<string>('')
const drawProgressColor = ref<string>('')

interface DosageType {
	dosage: number
	total: number | string
	expirationAt: string | null
}

const aiDrawState: DosageType = reactive({
	dosage: 0,
	total: '0',
	expirationAt: null,
})
const gpt3State: DosageType = reactive({
	dosage: 0,
	total: '',
	expirationAt: null,
})
const gpt4State: DosageType = reactive({
	dosage: 0,
	total: '',
	expirationAt: null,
})
const loginDeviceState: DosageType = reactive({
	dosage: 0,
	total: '0',
	expirationAt: null,
})

// const ms = useMessage()
const notification = useNotification()

const theme = computed(() => appStore.theme)
const meta = ref<string>('')
const userState = computed(() => userStore.userInfo)
//
// const avatar = ref(userState.value.avatar ?? '')
//
// const name = ref(userState.value.name ?? '')
//
// const description = ref(userState.value.description ?? '')

// const language = computed({
//   get() {
//     return appStore.language
//   },
//   set(value: Language) {
//     appStore.setLanguage(value)
//   },
// })
const isChecked = ref(true)

const themeOptions: { label: string; key: Theme; icon: string }[] = [
	{
		label: 'Auto',
		key: 'auto',
		icon: 'ri:contrast-line',
	},
	{
		label: 'Light',
		key: 'light',
		icon: 'ri:sun-foggy-line',
	},
	{
		label: 'Dark',
		key: 'dark',
		icon: 'ri:moon-foggy-line',
	},
]
//
// const languageOptions: { label: string; key: Language; value: Language }[] = [
//   {
//     label: '简体中文',
//     key: 'zh-CN',
//     value: 'zh-CN',
//   },
//   {
//     label: 'English',
//     key: 'en-US',
//     value: 'en-US',
//   },
// ]
const showVerifyModal = ref(false)
import {showLogin} from "@/hooks/LoginStatus";

function cancelCallback() {
	isChecked.value = true
}

function submitCallback() {
	handleLogout()
}

// function updateUserInfo(options: Partial<UserInfo>) {
//   userStore.updateUserInfo(options)
//   ms.success(t('common.success'))
// }

// function handleReset() {
//   userStore.resetUserInfo()
//   ms.success(t('common.success'))
//   window.location.reload()
// }

function handleLogout() {
	const token = Cookies.get('token')
	const config = {
		withCredentials: true,
		headers: {
			'content-type': 'application/json',
			'Authorization': `Bearer ${token}`,
		},
	}
	axios.get('/api/user/logout', config)
		.then((response) => {
			const result = response.data
			if (result.code === 200) {
				message.success('安全退出成功')
				authStore.removeToken()
				localStorage.removeItem("userInfo")
				userStore.resetUserInfo()
				Cookies.remove('token')
				window.location.reload()
			}
			// router.push('/login')
		}).catch(() => {
		message.success('安全退出成功')
		authStore.removeToken()
		userStore.resetUserInfo()
		Cookies.remove('token')
		window.location.reload()
		// router.push('/login')
	})
}

handleGetDosage()

function notify(type: NotificationType) {
	notification[type]({
		content: '提示',
		meta: meta.value,
		duration: 2500,
		keepAliveOnHover: true,
	})
}

function handleGetDosage() {
	const token = Cookies.get('token')
	const config = {
		withCredentials: true,
		headers: {
			'content-type': 'application/json',
			'Authorization': `Bearer ${token}`,
		},
	}
	axios.get('/api/getDosage', config)
		.then((response) => {
			const result = response.data
			if (result.code === 200) {
				const data = result.data

				const drawCount = data.aiDraw
				aiDrawState.dosage = drawCount.dosage
				aiDrawState.total = drawCount.total
				aiDrawState.expirationAt = drawCount.expirationAt

				const gpt3Count = data.gpt3
				gpt3State.dosage = gpt3Count.dosage
				gpt3State.total = gpt3Count.total
				gpt3State.expirationAt = gpt3Count.expirationAt

				if (gpt3Count.total === 0) {
					gpt3Percent.value = 0
				} else if (gpt3Count.total === '∞') {
					gpt3Percent.value = 100
				} else {
					gpt3Percent.value = 100 - Number(Number((gpt3State.dosage / (gpt3State.total + gpt3State.dosage)) * 100).toFixed(2))
				}
				if (gpt3Percent.value <= 10) {
					gpt3ProgressColor.value = themeVars.value.errorColor
				} else if (gpt3Percent.value > 10 && gpt3Percent.value <= 40) {
					gpt3ProgressColor.value = themeVars.value.warningColor
				} else {
					gpt3ProgressColor.value = themeVars.value.successColor
				}

				const gpt4Count = data.gpt4
				gpt4State.dosage = gpt4Count.dosage
				gpt4State.total = gpt4Count.total
				gpt4State.expirationAt = gpt4Count.expirationAt

				if (gpt4State.total === 0) {
					gpt4Percent.value = 0
				} else if (gpt4State.total === '∞') {
					gpt4Percent.value = 100
				} else {
					gpt4Percent.value = 100 - Number(Number((gpt4State.dosage / (gpt4State.total + gpt4State.dosage)) * 100).toFixed(2))
				}
				if (gpt4Percent.value <= 10) {
					gpt4ProgressColor.value = themeVars.value.errorColor
				} else if (gpt4Percent.value > 10 && gpt4Percent.value <= 40) {
					gpt4ProgressColor.value = themeVars.value.warningColor
				} else {
					gpt4ProgressColor.value = themeVars.value.successColor
				}

				const aiDrawCount = data.aiDraw
				aiDrawState.dosage = aiDrawCount.dosage
				aiDrawState.total = aiDrawCount.total
				if (aiDrawCount.total === 0) {
					drawPercent.value = 0
				} else if (aiDrawCount.total === '∞') {
					drawPercent.value = 100
				} else {
					drawPercent.value = 100 - Number(Number((aiDrawState.dosage / (aiDrawState.total + aiDrawState.dosage)) * 100).toFixed(2))
				}
				if (drawPercent.value <= 5) {
					drawProgressColor.value = themeVars.value.errorColor
				} else if (drawPercent.value > 10 && drawPercent.value <= 15) {
					drawProgressColor.value = themeVars.value.warningColor
				} else {
					drawProgressColor.value = themeVars.value.successColor
				}

				const loginDeviceCount = data.loginDevice
				loginDeviceState.dosage = loginDeviceCount.dosage
				loginDeviceState.total = loginDeviceCount.total
			}
		}).catch((error: any) => {
		if (error.response) {
			// 请求发生错误，获取状态码
			if (error.response.status === 401) {
				meta.value = '当前已经掉线了，请重新登录'
				if (showLogin.value) {
					notify('warning')
				}
				authStore.removeToken()
				Cookies.remove('token')
				showLogin.value = true
			}
		}
	})
}

// function exportData(): void {
//   const date = getCurrentDate()
//   const data: string = localStorage.getItem('chatStorage') || '{}'
//   const jsonString: string = JSON.stringify(JSON.parse(data), null, 2)
//   const blob: Blob = new Blob([jsonString], { type: 'application/json' })
//   const url: string = URL.createObjectURL(blob)
//   const link: HTMLAnchorElement = document.createElement('a')
//   link.href = url
//   link.download = `chat-store_${date}.json`
//   document.body.appendChild(link)
//   link.click()
//   document.body.removeChild(link)
// }
//
// function importData(event: Event): void {
//   const target = event.target as HTMLInputElement
//   if (!target || !target.files)
//     return
//
//   const file: File = target.files[0]
//   if (!file)
//     return
//
//   const reader: FileReader = new FileReader()
//   reader.onload = () => {
//     try {
//       const data = JSON.parse(reader.result as string)
//       localStorage.setItem('chatStorage', JSON.stringify(data))
//       ms.success(t('common.success'))
//       location.reload()
//     }
//     catch (error) {
//       ms.error(t('common.invalidFileFormat'))
//     }
//   }
//   reader.readAsText(file)
// }

// function clearData(): void {
//   localStorage.removeItem('chatStorage')
//   location.reload()
// }

// function handleImportButtonClick(): void {
//   const fileInput = document.getElementById('fileInput') as HTMLElement
//   if (fileInput)
//     fileInput.click()
// }

function handleCleanStorage() {
	localStorage.clear();
	message.success("清除成功")
	window.location.reload()
}

function handleSwitchChange() {
	if (!isChecked.value) {
		showVerifyModal.value = true
	} else {
		// 如果选择状态变为“未选中”，则不执行任何操作
		console.log('选择状态已变更为未选中。')
		showVerifyModal.value = false
	}
}

function getUserInfoByLocalStroge() {
	const userInfo = localStorage.getItem('userInfo')
	if (userInfo) {
		currentUserInfo = JSON.parse(userInfo)
		// console.log(currentUserInfo)
		userState.value.name = currentUserInfo.userName
		userState.value.avatar = currentUserInfo.headImages
		userState.value.role = currentUserInfo.role
	}
}

getUserInfoByLocalStroge()
</script>

<template>
	<n-modal
		v-model:show="showVerifyModal"
		preset="dialog"
		title="本站提示"
		content="若您不同意本站的免责申明条款，请立即停止使用本站"
		:bordered="false"
		positive-text="不接受并停止使用本站"
		negative-text="我已仔细阅读免责申明条款并同意"
		@positive-click="submitCallback"
		@negative-click="cancelCallback"
	/>

	<div class="p-4 space-y-5 min-h-[200px]">
		<div class="space-y-6">
			<div class="flex items-center space-x-4">
				<span class="flex-shrink-0 w-[100px]">用户名</span>
				<div class="flex-1">
					<p>{{ currentUserInfo.userName }}</p>
				</div>
			</div>
			<div class="flex items-center space-x-4">
				<span class="flex-shrink-0 w-[100px]">手机号码</span>
				<div class="flex-1">
					<p>{{ currentUserInfo.phone }}</p>
				</div>
			</div>
			<div class="flex items-center space-x-4">
				<span class="flex-shrink-0 w-[100px]">账户类型</span>
				<div class="flex-1">
					<n-tag v-if="currentUserInfo.role.includes('3.5模型VIP')"  :strong="true" :type="'warning'" :size="'large'">
						3.5模型VIP
						<template #avatar>
							<n-avatar
								src="https://chat-gpt-1252138783.cos.ap-chengdu.myqcloud.com/icon/Vip3.png"
							/>
						</template>
						&nbsp;
					</n-tag>

					<n-tag v-if="currentUserInfo.role.includes('4.0模型VIP')" :strong="true" :type="'warning'" :size="'large'">
						4.0模型VIP
						<template #avatar>
							<n-avatar
								src="https://chat-gpt-1252138783.cos.ap-chengdu.myqcloud.com/icon/Vip4.png"
							/>
						</template>
						&nbsp;

					</n-tag>
					<n-tag v-if="currentUserInfo.role.includes('永久会员')" :strong="true" :type="'warning'" :size="'large'">
						永久会员
						<template #avatar>
							<n-avatar
								src="https://chat-gpt-1252138783.cos.ap-chengdu.myqcloud.com/icon/YJ.png"
							/>
						</template>
						&nbsp;
					</n-tag>
					<n-tag v-if="currentUserInfo.role.includes('普通用户')" :strong="true"  :size="'large'" >
						普通会员
						<template #avatar>
							<n-avatar
								src="https://chat-gpt-1252138783.cos.ap-chengdu.myqcloud.com/icon/hy.png "
							/>
						</template>
					</n-tag>
				</div>
			</div>
			<div class="flex items-center space-x-4">
				<span class="flex-shrink-0 w-[100px]">GPT3.5Turbo</span>
				<div class="flex-1">
					<n-progress
						type="line"
						:percentage="gpt3Percent"
						:color="gpt3ProgressColor"
						indicator-placement="inside"
						processing
					/>
					<p>{{ gpt3State.dosage }} (用量) / {{ gpt3State.total }} (余量)</p>
					<p v-if="gpt3State.expirationAt != null">
						到期时间：{{ gpt3State.expirationAt }}
					</p>
				</div>
			</div>
			<div class="flex items-center space-x-4">
				<span class="flex-shrink-0 w-[100px]">GPT4.0</span>
				<div class="flex-1">
					<n-progress
						type="line"
						:percentage="gpt4Percent"
						:color="gpt4ProgressColor"
						indicator-placement="inside"
						processing
					/>
					<p>{{ gpt4State.dosage }} (用量) / {{ gpt4State.total }} (余量)</p>
					<p v-if="currentUserInfo.role.includes('3.5模型VIP')" class = 'font-show-size'>(会员每日赠送额度)</p>
					<p v-if="gpt4State.expirationAt != null">
						到期时间：{{ gpt4State.expirationAt }}
					</p>
				</div>
			</div>
			<div class="flex items-center space-x-4">
				<span class="flex-shrink-0 w-[100px]">AI绘画余量</span>
				<div class="flex-1">
					<n-progress
						type="line"
						indicator-placement="inside"
						:color="drawProgressColor"
						:percentage="drawPercent"
						processing
					/>
					<p>{{ aiDrawState.dosage }} (用量) / {{ aiDrawState.total }} (余量)</p>
					<p v-if="aiDrawState.expirationAt != null">
						到期时间：{{ aiDrawState.expirationAt }}
					</p>
				</div>
			</div>
			<div class="flex items-center space-x-4">
				<span class="flex-shrink-0 w-[100px]">登录设备</span>
				<div class="flex-1">
					<p>{{ loginDeviceState.dosage }} / {{ loginDeviceState.total }}</p>
				</div>
			</div>
			<div class="flex items-center space-x-4">
				<span class="flex-shrink-0 w-[100px]">清除缓存</span>
				<div class="flex-1">
					<n-button type="primary" size="small" @click="handleCleanStorage" dashed>
						清除缓存
					</n-button>
					<br>
					<span style="color: #999999;font-size: 10px;">
					所有对话记录都保存在本地，清除缓存将清空本地所有对话记录。
				</span>
				</div>
			</div>
			<div class="flex items-center space-x-4">
        <span class="flex-shrink-0 w-[100px]"><a
					target="_blank" style="color:#006eff;"
					href="https://docs.qq.com/doc/DYmxlTmt2ZXNYaWN0"
				>免责声明</a></span>
				<n-switch v-model:value="isChecked" @update:value="handleSwitchChange">
					<template #checked>
						已同意并遵守
					</template>
					<template #unchecked>
						不同意
					</template>
				</n-switch>
			</div>

<!--			<div class="flex items-center space-x-4">-->
<!--				<span class="flex-shrink-0 w-[100px]">{{ $t('setting.theme') }}</span>-->
<!--				<div class="flex flex-wrap items-center gap-4">-->
<!--					<template v-for="item of themeOptions" :key="item.key">-->
<!--						<NButton-->
<!--							size="small"-->
<!--							:type="item.key === theme ? 'primary' : undefined"-->
<!--							@click="appStore.setTheme(item.key)"-->
<!--						>-->
<!--							<template #icon>-->
<!--								<SvgIcon :icon="item.icon"/>-->
<!--							</template>-->
<!--						</NButton>-->
<!--					</template>-->
<!--				</div>-->
<!--			</div>-->

			<!--      <div class="flex items-center space-x-4"> -->
			<!--        <span class="flex-shrink-0 w-[100px]">{{ $t('setting.avatarLink') }}</span> -->
			<!--        <div class="flex-1"> -->
			<!--          <NInput v-model:value="avatar" placeholder="" /> -->
			<!--        </div> -->
			<!--        <NButton size="tiny" text type="primary" @click="updateUserInfo({ avatar })"> -->
			<!--          {{ $t('common.save') }} -->
			<!--        </NButton> -->
			<!--      </div> -->
			<!--      <div class="flex items-center space-x-4"> -->
			<!--        <span class="flex-shrink-0 w-[100px]">{{ $t('setting.name') }}</span> -->
			<!--        <div class="w-[200px]"> -->
			<!--          <NInput v-model:value="name" placeholder="" /> -->
			<!--        </div> -->
			<!--        <NButton size="tiny" text type="primary" @click="updateUserInfo({ name })"> -->
			<!--          {{ $t('common.save') }} -->
			<!--        </NButton> -->
			<!--      </div> -->
			<!--      <div class="flex items-center space-x-4"> -->
			<!--        <span class="flex-shrink-0 w-[100px]">{{ $t('setting.description') }}</span> -->
			<!--        <div class="flex-1"> -->
			<!--          <NInput v-model:value="description" placeholder="" /> -->
			<!--        </div> -->
			<!--        <NButton size="tiny" text type="primary" @click="updateUserInfo({ description })"> -->
			<!--          {{ $t('common.save') }} -->
			<!--        </NButton> -->
			<!--      </div> -->

			<!--      <div class="flex items-center space-x-4"> -->
			<!--        <span class="flex-shrink-0 w-[100px]">{{ $t('setting.chatHistory') }}</span> -->

			<!--        <div class="flex flex-wrap items-center gap-4"> -->
			<!--          <NButton size="small" @click="exportData"> -->
			<!--            <template #icon> -->
			<!--              <SvgIcon icon="ri:download-2-fill" /> -->
			<!--            </template> -->
			<!--            {{ $t('common.export') }} -->
			<!--          </NButton> -->

			<!--          <input id="fileInput" type="file" style="display:none" @change="importData"> -->
			<!--          <NButton size="small" @click="handleImportButtonClick"> -->
			<!--            <template #icon> -->
			<!--              <SvgIcon icon="ri:upload-2-fill" /> -->
			<!--            </template> -->
			<!--            {{ $t('common.import') }} -->
			<!--          </NButton> -->

			<!--          <NPopconfirm placement="bottom" @positive-click="clearData"> -->
			<!--            <template #trigger> -->
			<!--              <NButton size="small"> -->
			<!--                <template #icon> -->
			<!--                  <SvgIcon icon="ri:close-circle-line" /> -->
			<!--                </template> -->
			<!--                {{ $t('common.clear') }} -->
			<!--              </NButton> -->
			<!--            </template> -->
			<!--            {{ $t('chat.clearHistoryConfirm') }} -->
			<!--          </NPopconfirm> -->
			<!--        </div> -->
			<!--      </div> -->
			<!--      <div class="flex items-center space-x-4"> -->
			<!--        <span class="flex-shrink-0 w-[100px]">{{ $t('setting.theme') }}</span> -->
			<!--        <div class="flex flex-wrap items-center gap-4"> -->
			<!--          <template v-for="item of themeOptions" :key="item.key"> -->
			<!--            <NButton -->
			<!--              size="small" -->
			<!--              :type="item.key === theme ? 'primary' : undefined" -->
			<!--              @click="appStore.setTheme(item.key)" -->
			<!--            > -->
			<!--              <template #icon> -->
			<!--                <SvgIcon :icon="item.icon" /> -->
			<!--              </template> -->
			<!--            </NButton> -->
			<!--          </template> -->
			<!--        </div> -->
			<!--      </div> -->
			<!--      <div class="flex items-center space-x-4"> -->
			<!--        <span class="flex-shrink-0 w-[100px]">{{ $t('setting.language') }}</span> -->
			<!--        <div class="flex flex-wrap items-center gap-4"> -->
			<!--          <template v-for="item of languageOptions" :key="item.key"> -->
			<!--            <NButton -->
			<!--              size="small" -->
			<!--              :type="item.key === language ? 'primary' : undefined" -->
			<!--              @click="appStore.setLanguage(item.key)" -->
			<!--            > -->
			<!--              {{ item.label }} -->
			<!--            </NButton> -->
			<!--          </template> -->
			<!--        </div> -->
			<!--      </div> -->
			<div class="flex items-center space-x-4">
				<!--        <span class="flex-shrink-0 w-[100px]">{{ $t('setting.resetUserInfo') }}</span> -->
				<!--                <NButton size="small" @click="handleReset"> -->
				<!--          {{ $t('common.reset') }} -->
				<!--        </NButton> -->
				<NButton type="warning" dashed @click="handleLogout">
					退出登录
				</NButton>
			</div>
		</div>
	</div>
</template>
<style>
.font-show-size {
	color: #999999;
	font-size: 12px;
}
</style>
