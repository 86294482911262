<script lang="ts" setup>
import axios from 'axios'
import Cookies from 'js-cookie'
import { reactive, ref } from 'vue'
import { NButton, useMessage } from 'naive-ui'
import type { NotificationType } from 'naive-ui'
import { useAuthStore } from '@/store'
const emit = defineEmits(['customEvent'])
// import type { Theme } from '@/store/modules/app/helper'
// import { useAuthStore } from '@/store'
// import { getCurrentDate } from '@/utils/functions'
// import { t } from '@/locales'
const notification = useNotification()
// const appStore = useAppStore()
// const userStore = useUserStore()
const message = useMessage()
// const authStore = useAuthStore()
import {showLogin} from "@/hooks/LoginStatus";

interface promptConfig {
  registerId: string
  dialogNumber: number
  temperature: number
  topP: number
}

let currentUserInfo = reactive({
  id: '',
  userId: '',
  userName: '',
  phone: '',
  userFlag: '',
  headImages: '',
  role: '',
})
const meta = ref<string>('')
const authStore = useAuthStore()

const promptState: promptConfig = reactive({
  registerId: '',
  dialogNumber: 0.0,
  temperature: 0.0,
  topP: 0,
})

const temperatureMarks = {
  0.8: '0.8',
}
const topMarks = {
  0.2: '0.2',
}

const talksMarks = {
  6: '6',
}

// const ms = useMessage()

// const theme = computed(() => appStore.theme)

// const userInfo = computed(() => userStore.userInfo)

// const avatar = ref(userInfo.value.avatar ?? '')

// const name = ref(userInfo.value.name ?? '')

// const description = ref(userInfo.value.description ?? '')

// const language = computed({
//   get() {
//     return appStore.language
//   },
//   set(value: Language) {
//     appStore.setLanguage(value)
//   },
// })
//
// const themeOptions: { label: string; key: Theme; icon: string }[] = [
//   {
//     label: 'Auto',
//     key: 'auto',
//     icon: 'ri:contrast-line',
//   },
//   {
//     label: 'Light',
//     key: 'light',
//     icon: 'ri:sun-foggy-line',
//   },
//   {
//     label: 'Dark',
//     key: 'dark',
//     icon: 'ri:moon-foggy-line',
//   },
// ]

// const languageOptions: { label: string; key: Language; value: Language }[] = [
//   {
//     label: '简体中文',
//     key: 'zh-CN',
//     value: 'zh-CN',
//   },
//   {
//     label: 'English',
//     key: 'en-US',
//     value: 'en-US',
//   },
// ]
// function updateUserInfo(options: Partial<UserInfo>) {
//   userStore.updateUserInfo(options)
//   ms.success(t('common.success'))
// }
//
// function handleReset() {
//   userStore.resetUserInfo()
//   ms.success(t('common.success'))
//   window.location.reload()
// }

function notify(type: NotificationType) {
  notification[type]({
    content: '提示',
    meta: meta.value,
    duration: 2500,
    keepAliveOnHover: true,
  })
}

handleGetPromptConfig()

function handleGetPromptConfig() {
  const token = Cookies.get('token')
  const config = {
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  }
  axios.get('/api/getPromptConfig', config)
    .then((response) => {
      const result = response.data
      if (result.code === 200) {
        const data = result.data
        promptState.registerId = data.registerId
        promptState.dialogNumber = data.dialogNumber
        promptState.temperature = data.temperature
        promptState.topP = data.topP
        localStorage.setItem('promptConfig', JSON.stringify(promptState))
      }
    }).catch((error: any) => {
      if (error.response) {
        // 请求发生错误，获取状态码
        if (error.response.status === 401) {
          meta.value = '当前已经掉线了，请重新登录'
          if (showLogin.value)
            notify('warning')
          authStore.removeToken()
          Cookies.remove('token')
					showLogin.value = true
        }
      }
    })
}

function handlePutPromptConfig() {
  const token = Cookies.get('token')
  const config = {
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  }

  axios.post('/api/putPromptConfig', promptState, config)
    .then((response) => {
      const result = response.data
      if (result.code === 200)
        message.success('保存成功')

      localStorage.setItem('promptConfig', JSON.stringify(promptState))

      emit('customEvent')
    }).catch(() => {
      // alert()
      message.warning('保存失败,但不影响使用，可以稍候在尝试保存')
      // this.$emit('custom-event', '---')
    })
}

//
// function exportData(): void {
//   const date = getCurrentDate()
//   const data: string = localStorage.getItem('chatStorage') || '{}'
//   const jsonString: string = JSON.stringify(JSON.parse(data), null, 2)
//   const blob: Blob = new Blob([jsonString], { type: 'application/json' })
//   const url: string = URL.createObjectURL(blob)
//   const link: HTMLAnchorElement = document.createElement('a')
//   link.href = url
//   link.download = `chat-store_${date}.json`
//   document.body.appendChild(link)
//   link.click()
//   document.body.removeChild(link)
// }
//
// function importData(event: Event): void {
//   const target = event.target as HTMLInputElement
//   if (!target || !target.files)
//     return
//
//   const file: File = target.files[0]
//   if (!file)
//     return
//
//   const reader: FileReader = new FileReader()
//   reader.onload = () => {
//     try {
//       const data = JSON.parse(reader.result as string)
//       localStorage.setItem('chatStorage', JSON.stringify(data))
//       ms.success(t('common.success'))
//       location.reload()
//     }
//     catch (error) {
//       ms.error(t('common.invalidFileFormat'))
//     }
//   }
//   reader.readAsText(file)
// }

// function clearData(): void {
//   localStorage.removeItem('chatStorage')
//   location.reload()
// }
//
// function handleImportButtonClick(): void {
//   const fileInput = document.getElementById('fileInput') as HTMLElement
//   if (fileInput)
//     fileInput.click()
// }

function getUserInfoByLocalStroge() {
  const userInfo = localStorage.getItem('userInfo')
  if (userInfo) {
    currentUserInfo = JSON.parse(userInfo)
    // console.log(currentUserInfo)
  }
}

getUserInfoByLocalStroge()
</script>

<template>
  <div class="p-4 space-y-5 min-h-[200px]">
    <div class="space-y-6">
      <div class="flex  space-x-4">
        <span class="flex-shrink-0 w-[100px]">温度采样</span>
        <div class="flex-1">
          <n-slider
            v-model:value="promptState.temperature" :keyboard="true" tooltip placement="bottom"
            :marks="temperatureMarks" :min="0.1" :max="1.5"
            :step="0.1"
          />
          <p class="font-show-size">
            温度采样，较高的值（1 以上）将使输出更加随机（也可能乱码或卡住），而较低的值（如 0.2）将使输出更加集中和确定，温度采样与核心采样应当避免同时调整。
          </p>
        </div>
      </div>
      <div class="flex  space-x-4">
        <span class="flex-shrink-0 w-[100px] ">核心采样</span>
        <div class="flex-1">
          <n-slider
            v-model:value="promptState.topP" :keyboard="true" tooltip :marks="topMarks" :min="0.1" :max="1"
            :step="0.1"
          />
          <p class="font-show-size">
            核心采样，模型只考虑概率质量为核心的前百分之几的结果。比如 0.1 的意思是只有概率质量前 10% 的标记会被考虑。
          </p>
        </div>
      </div>
      <div class="flex space-x-4">
        <span class="flex-shrink-0 w-[100px] ">会话携带数量</span>
        <div class="flex-1">
          <n-slider
            v-model:value="promptState.dialogNumber" :keyboard="true" tooltip :marks="talksMarks" :min="1"
            :max="15" :step="1"
          />
          <p class="font-show-size">
            携带的上下文应设置合适的数量，携带的过多不相关上文可能会干扰最新提问的输出，默认6，可随时调整。
          </p>
        </div>
      </div>

      <div class="flex items-center space-x-4">
        <!--        <span class="flex-shrink-0 w-[100px]">{{ $t('setting.resetUserInfo') }}</span> -->
        <!--                <NButton size="small" @click="handleReset"> -->
        <!--          {{ $t('common.reset') }} -->
        <!--        </NButton> -->
        <NButton type="success" dashed @click="handlePutPromptConfig">
          保存参数
        </NButton>
      </div>
    </div>
  </div>
</template>

<style scoped>
.font-show-size {
	color: #999999;
	font-size: 12px;
}
</style>
