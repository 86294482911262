<script lang="ts" setup>
import {SvgIcon} from "@/components/common";
import {setLocalState} from "@/store/modules/chat/helper";
import axios from 'axios'
import {reactive, ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import type {NotificationType} from 'naive-ui'
import {useMessage} from 'naive-ui'
import Cookies from 'js-cookie'
import {useAuthStore, useUserStore} from '@/store'
import wxLoginImg from '@/assets/wxLogin.gif'
import refreshLoginQr from '@/assets/refreshLoginQr.png'

const userStore = useUserStore()

// 显示登录提示模态框
let currentUserInfo = reactive({
	id: '',
	userId: '',
	userName: '',
	phone: '',
	userFlag: '',
	headImages: '',
	role: '',
	wxOpenId: ''
})

interface FormState {
	phone: string
	password: string
}

interface RegisterFormState {
	phone: string
	password: string
	verifyCode: string
	wxOpenId?: string
}

const loginStatus = inject('showLogin');
const formRef = ref()
const message = useMessage()
const loading = ref(false)
const LOGIN_NAME = 'Login'
const flag = ref<String>('登录')
const flagTitle = ref<String>('登录打工人AI，获取免费对话次数')
const checked = ref(false)
const showCheckoutDiv = ref(true)
const verifyStatus = ref<String>('0')
const sendingCode = ref<boolean>(false)
const remainTime = ref<number>(0)
const meta = ref<string>('')
const authStore = useAuthStore()
const registerRules = {
	phone: [
		// {
		//   required: true,
		//   message: '请输入手机号码',
		//   trigger: 'blur',
		// },
		{
			required: true,
			max: 11,
			pattern: /^1[0-9]{10}$/,
			message: '请输入正确的手机号码',
			trigger: 'blur',
		},
	],
	password: [
		{
			required: true,
			min: 6,
			message: '密码长度不能小于6位',
			trigger: 'blur',
		}
	],
	verifyCode: [
		{
			required: true,
			message: '请输入验证码',
			trigger: 'blur',
		}
	],
}

const formInline = reactive({
	phone: '',
	password: '',
	isCaptcha: true,
})

const formInline2 = reactive({
	phone: '',
	password: '',
	verifyCode: '',
	wxOpenId: '',
})

const rules = {
	phone: [{
		required: true,
		message: '请输入手机号码',
		trigger: 'blur',
	},
		{
			min: 11,
			max: 11,
			message: '手机号码必须是11位',
			trigger: 'blur'
		}],
	password: [
		{
			required: true,
			message: '请输入密码,应该确保大于6位',
			trigger: 'blur',
		},
		{
			required: true,
			min: 6,
			message: '密码长度不能小于6位',
			trigger: 'blur',
		},
		{
			required: true,
			pattern: /^[^\s]*$/,
			message: '密码不能包含空格',
			trigger: 'blur',
		},
	],
}

// 监听count状态的变化
watch(() => flag.value, (newValue, oldValue) => {
	if (newValue === "登录") {
		showCheckoutDiv.value = true
	} else {
		showCheckoutDiv.value = false
	}
});

// const userStore = useUserStore()
const notification = useNotification()
const registerMessage = useMessage()
const router = useRouter()
const route = useRoute()

const onlyAllowNumber = (value: string) => !value || /^\d+$/.test(value)

function startCountdown() {
	remainTime.value = 60 // 设置倒计时初始值为 60 秒
	const timer = setInterval(() => {
		remainTime.value -= 1
		if (remainTime.value <= 0) {
			clearInterval(timer) // 清除定时器
			remainTime.value = 0 // 重置倒计时剩余秒数
		}
	}, 1000)
}

// 提示
function notify(type: NotificationType) {
	notification[type]({
		content: '提示',
		meta: meta.value,
		duration: 2500,
		keepAliveOnHover: true,
	})
}

function handleVerifyPhone() {
	if (formInline2.phone.length !== 11) {
		meta.value = '手机号码不正确'
		notify('warning')
		return
	}
	startCountdown()
	sendingCode.value = true

	axios.get(`/api/user/verifyPhone/${verifyStatus.value}/${formInline2.phone}`)
		.then((response) => {
			const result = response.data
			if (result.code === 200) {
				if (result.data === 'FAILURE') {
					registerMessage.warning(
						result.msg,
						{duration: 10000},
					)
					return
				}
				if (result.data === 10022) {
					registerMessage.warning(
						result.msg,
						{duration: 5000},
					)
				}
				if (result.data === 'success') {
					meta.value = '短信已下发'
					notify('success')
				}
			}
		})
		.catch((error) => {
			console.error(error)
		})
}

function handleRegister() {
	flag.value = '注册'
	flagTitle.value = '注册为正式用户'
}

const handleUpdateChecked = (value: boolean) => {
	console.log('checkbox 状态更新：', value)
	checked.value = value

	// 这里可以写自己的逻辑代码
}

function handleForgotPwd() {
	verifyStatus.value = 'check'
	flag.value = '找回密码'
	flagTitle.value = '重置密码'
}

// 重置密码
const handleForgotPwdSub = (e: { preventDefault: () => void }) => {
	e.preventDefault()
	formRef.value.validate(async (errors: any) => {
		if (!errors) {
			const {
				phone,
				password,
				verifyCode,
			} = formInline2
			message.loading('重置密码中，请稍后')
			loading.value = true

			const params: RegisterFormState = {
				phone,
				password,
				verifyCode,
			}

			const config = {
				headers: {
					'content-type': 'application/json',
					'Accept': 'application/json',
				},
			}

			try {
				axios.put('/api/user/resetPwd', {
					phone: params.phone,
					password: params.password,
					verifyCode: params.verifyCode,
				}, config)
					.then((response) => {
						const result = response.data
						message.destroyAll()
						if (result.code === 200) {
							if (result.data !== 'SUCCESS') {
								meta.value = result.data
								notify('warning')
								return
							}
							const toPath = decodeURIComponent((route.path || '/') as string)
							message.success('密码已重置，自动登录系统')
							// 在 cookie 中存储名为 "token" 的键值对，有效期为 7 天
							Cookies.set('token', result.msg, {expires: 7})
							authStore.setToken(result.msg)
							if (route.name === LOGIN_NAME) {
								router.replace('/')
							} else {
								router.go(0)
								router.replace(toPath)
							}
						} else if (result.code === 10009) {
							message.info(result.msg || '操作失败')
						}
					})
					.catch((error) => {
						message.info(error || '操作失败')
					})
			} finally {
				loading.value = false
			}
		} else {
			message.error('请填写完整信息，并且进行验证码校验')
		}
	})
}

// 绑定手机号码
const bindPhone = (e: { preventDefault: () => void }) => {
	e.preventDefault()
	formRef.value.validate(async (errors: any) => {
		if (!errors) {
			const {
				phone,
				password,
				verifyCode,
				wxOpenId
			} = formInline2
			message.loading('正在绑定手机号码，请稍后')
			loading.value = true

			const params: RegisterFormState = {
				phone,
				password,
				verifyCode,
				wxOpenId,
			}
			const token = Cookies.get('token')
			const config = {
				withCredentials: true,
				headers: {
					'content-type': 'application/json',
					'Accept': 'application/json',
					'Authorization': `Bearer ${token}`,
				},
			}

			const userInfo = localStorage.getItem('userInfo')
			currentUserInfo = JSON.parse(userInfo)

			try {
				axios.put('/api/user/bindPhone', {
					phone: params.phone,
					password: params.password,
					verifyCode: params.verifyCode,
					wxOpenId: currentUserInfo.wxOpenId,
				}, config)
					.then((response) => {
						const result = response.data
						message.destroyAll()
						if (result.code === 200) {
							if (result.data !== 'SUCCESS') {
								meta.value = result.data
								notify('warning')
								return
							}
							const toPath = decodeURIComponent((route.path || '/') as string)
							message.success('绑定账号成功，即将回到系统')
							// 在 cookie 中存储名为 "token" 的键值对，有效期为 7 天
							Cookies.set('token', result.msg, {expires: 7})
							authStore.setToken(result.msg)
							if (route.name === LOGIN_NAME) {
								router.replace('/')
							} else {
								router.go(0)
								router.replace(toPath)
							}
							loginStatus.value = false
						} else if (result.code === 10009) {
							message.info(result.msg || '操作失败')
						}
					})
					.catch((error) => {
						message.info(error || '操作失败')
					})
			} finally {
				loading.value = false
			}
		} else {
			message.error('请填写完整信息，并且进行验证码校验')
		}
	})
}

const handleRegisterSub = (e: { preventDefault: () => void }) => {
	e.preventDefault()
	formRef.value.validate(async (errors: any) => {
		if (!errors) {
			const {
				phone,
				password,
				verifyCode,
			} = formInline2
			message.loading('正在注册，请稍后')
			loading.value = true

			const params: RegisterFormState = {
				phone,
				password,
				verifyCode,
			}

			const config = {
				headers: {
					'content-type': 'application/json',
					'Accept': 'application/json',
				},
			}

			try {
				axios.post('/api/user/registerUser', {
					phone: params.phone,
					password: params.password,
					verifyCode: params.verifyCode,
				}, config)
					.then((response) => {
						const result = response.data
						message.destroyAll()
						if (result.code === 200) {
							if (result.data !== 'SUCCESS') {
								meta.value = result.msg
								notify('warning')
								return
							}
							const toPath = decodeURIComponent((route.path || '/') as string)
							message.success('注册成功，自动登录系统')
							// 在 cookie 中存储名为 "token" 的键值对，有效期为 7 天
							Cookies.set('token', result.msg, {expires: 7})

							if (route.name === LOGIN_NAME) {
								router.replace('/')
							} else {
								router.go(0)
								router.replace(toPath)
							}
							loginStatus.value = false
						} else if (result.code === 10009) {
							message.info(result.msg || '注册失败')
						}
					})
					.catch((error) => {
						message.info(error || '注册失败')
					})
			} finally {
				loading.value = false
			}
		} else {
			message.error('请填写完整信息，并且进行验证码校验')
		}
	})
}
const dialog = useDialog()

function handleLogin() {
	verifyStatus.value = '0'
	flag.value = '登录'
	flagTitle.value = '登录打工人AI，获取免费对话次数'
}

//登录方式标识 true 账号密码登录 false 微信登录
const LoginFlag = ref(true)

//登录样式
const LoginStyle = ref('qrStyle')

function handleQrLogin() {
	LoginFlag.value = !LoginFlag.value
	if (!LoginFlag.value) {
		LoginStyle.value = "pwdStyle"
		getWxLoginQr()
	} else {
		LoginStyle.value = "qrStyle"
		condition.value = true
	}
}

const titleStr = ref('请使用手机微信扫码以登录')

const qrUrl = ref<String>('')
//定时器开关
const condition = ref(false);
//获取二维码后随即启动定时任务
let timer;

function getWxLoginQr() {
	titleStr.value = "请使用手机微信扫码以登录"

	axios.get('/api/user/getLoginQrCode')
		.then((response) => {
			const result = response.data
			if (result.code === 10007) {
				handleQrLogin();
				message.error(result.msg || '获取二维码失败')
				return
			}
			if (result.code === 200) {
				//执行前先清除定时器
				clearInterval(timer);
				if (result.data.loginQrUrl) {
					qrUrl.value = result.data.loginQrUrl
					timer = setInterval(() => {
						// 每5秒执行一次的逻辑
						axios.get("/api/user/getTokenByWxLoginId/" + result.data.sceneStr + "?date=" + Date.now())
							.then((response) => {
								const result = response.data
								message.destroyAll()
								if (result.code === 200) {
									const toPath = decodeURIComponent((route.path || '/') as string)
									message.success('微信已授权访问，欢迎回来')
									condition.value = true
									// 在 cookie 中存储名为 "token" 的键值对，有效期为 7 天
									Cookies.set('token', result.data, {expires: 7})
									authStore.setToken(result.data)
									if (route.name === LOGIN_NAME) {
										router.replace('/')
									} else {
										router.go(0)
										router.replace(toPath)
									}
									loginStatus.value = false
								} else if (result.code === 10010) {
									qrUrl.value = refreshLoginQr
									condition.value = true
									titleStr.value = "二维码已失效，请刷新后再试"
									message.info(result.msg || '登录失败')
								} else if (result.code === 40258) {
									condition.value = false
								}
							}).catch((error) => {
							condition.value = true
							message.error("暂不能微信扫码登录，请尝试用账号密码登录")
						})
						if (condition.value) {
							clearInterval(timer);
						}
					}, 1000);
				}
			}
		})
		.catch((error: any) => {
			message.info('登录失败,请稍后再试')
		})
}

checkUserInfo()

// 判断字符串是否为 null 或空字符串
function isNullOrEmpty(str) {
	if (str == null) {
		return true
	}
	if (str.trim() === '') {
		return true
	}
	return false
}

function checkUserInfo() {
	let phone = userStore.userInfo.phone;
	let wxOpenId = userStore.userInfo.openId;
	if (isNullOrEmpty(phone) && !isNullOrEmpty(wxOpenId)) {
		verifyStatus.value = 'check'
		flag.value = '绑定手机号码'
		flagTitle.value = '绑定手机号码'
	} else {
		flag.value = '登录'
		flagTitle.value = '登录打工人AI，获取免费对话次数'
	}
	console.log('checkUserInfo', flag.value)
}

const handleSubmit = (e: { preventDefault: () => void }) => {
	e.preventDefault()
	formRef.value.validate(async (errors: any) => {
		if (!errors) {
			const {
				phone,
				password,
			} = formInline
			message.loading('登录中,请稍后')
			loading.value = true

			const params: FormState = {
				phone,
				password,
			}

			const config = {
				headers: {
					'content-type': 'application/json',
					'Accept': 'application/json',
				},
			}

			try {
				axios.post('/api/user/login', {
					phone: params.phone,
					password: params.password,
				}, config)
					.then((response) => {
						const result = response.data
						message.destroyAll()
						if (result.code === 200) {
							const toPath = decodeURIComponent((route.path || '/') as string)
							message.success('账户验证通过，欢迎回来，今日免费用量已到账')
							// dialog.success({
							// 	title: '提醒',
							// 	content: '本平台坚决拥护中国共产党的领导，坚决抵制任何反动言论，维护国家信息安全，立志建设一个美好的网络环境。在使用本平台的过程中请您务必不要输入任何有关国家和企业相关机密以及不合规、不道德的内容，否则平台将有权采取强制封号措施并配合公安机关依法追查。',
							// 	positiveText: '确定',
							// 	onPositiveClick: () => {
							// 	}
							// })

							// 在 cookie 中存储名为 "token" 的键值对，有效期为 7 天
							Cookies.set('token', result.msg, {expires: 7})
							authStore.setToken(result.msg)
							if (route.name === LOGIN_NAME) {
								router.push('/')
							} else {
								router.go(0)
								router.replace(toPath)
							}
							loginStatus.value = false
						} else if (result.code === 10005) {
							message.info(result.msg || '登录失败')
						} else if (result.code === 10021) {
							message.info(result.msg || '登录失败')
						}
					})
					.catch((error: any) => {
						message.destroyAll()
						message.info('登录失败,请稍后再试')
					})
			} finally {
				loading.value = false
			}
		} else {
			message.error('请填写完整信息，并且进行验证码校验')
		}
	})
}
</script>

<template style="margin: 0">
	<div class="view-account">
		<div class="zppp-panel-normal-bar" v-if="showCheckoutDiv">
			<div :class="LoginStyle" @click="handleQrLogin"></div>
			<p class="zppp-panel-normal-bar__text" style="display: none;">微信扫码快捷登录</p>
		</div>
		<div class="view-account-header"/>
		<div class="view-account-container">

			<div class="view-account-top">
				<div class="view-account-top-logo">
					<svg
						data-v-10d94259="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" width="4em"
						height="4em" style="color: rgb(24, 160, 88);"
					>
						<path
							data-v-10d94259=""
							d="M29.71,13.09A8.09,8.09,0,0,0,20.34,2.68a8.08,8.08,0,0,0-13.7,2.9A8.08,8.08,0,0,0,2.3,18.9,8,8,0,0,0,3,25.45a8.08,8.08,0,0,0,8.69,3.87,8,8,0,0,0,6,2.68,8.09,8.09,0,0,0,7.7-5.61,8,8,0,0,0,5.33-3.86A8.09,8.09,0,0,0,29.71,13.09Zm-12,16.82a6,6,0,0,1-3.84-1.39l.19-.11,6.37-3.68a1,1,0,0,0,.53-.91v-9l2.69,1.56a.08.08,0,0,1,.05.07v7.44A6,6,0,0,1,17.68,29.91ZM4.8,24.41a6,6,0,0,1-.71-4l.19.11,6.37,3.68a1,1,0,0,0,1,0l7.79-4.49V22.8a.09.09,0,0,1,0,.08L13,26.6A6,6,0,0,1,4.8,24.41ZM3.12,10.53A6,6,0,0,1,6.28,7.9v7.57a1,1,0,0,0,.51.9l7.75,4.47L11.85,22.4a.14.14,0,0,1-.09,0L5.32,18.68a6,6,0,0,1-2.2-8.18Zm22.13,5.14-7.78-4.52L20.16,9.6a.08.08,0,0,1,.09,0l6.44,3.72a6,6,0,0,1-.9,10.81V16.56A1.06,1.06,0,0,0,25.25,15.67Zm2.68-4-.19-.12-6.36-3.7a1,1,0,0,0-1.05,0l-7.78,4.49V9.2a.09.09,0,0,1,0-.09L19,5.4a6,6,0,0,1,8.91,6.21ZM11.08,17.15,8.38,15.6a.14.14,0,0,1-.05-.08V8.1a6,6,0,0,1,9.84-4.61L18,3.6,11.61,7.28a1,1,0,0,0-.53.91ZM12.54,14,16,12l3.47,2v4L16,20l-3.47-2Z"
							fill="currentColor"
						/>
					</svg>
				</div>
				<div class="view-account-top-desc">
					<b>{{ flagTitle }}</b>
				</div>
			</div>

			<!--	登录框	-->
			<div v-if="flag === '登录'">
				<div v-if="LoginFlag">
					<NForm
						ref="formRef"
						label-placement="left"
						size="large"
						:model="formInline"
						:rules="rules"
					>
						<NFormItem path="phone">
							<n-input v-model:value="formInline.phone" @keyup.enter="handleSubmit" placeholder="请输入手机号"
											 clearable
							>
								<template #prefix>
									<n-icon size="18" color="#808695">
										<PersonOutline/>
									</n-icon>
								</template>
							</n-input>
						</NFormItem>
						<NFormItem path="password">
							<n-input
								v-model:value="formInline.password"
								type="password"
								@keyup.enter="handleSubmit"
								show-password-on="click"
								placeholder="请输入密码"
								clearable
							>
								<template #prefix>
									<n-icon size="18" color="#808695">
										<LockClosedOutline/>
									</n-icon>
								</template>
							</n-input>
						</NFormItem>
						<NFormItem>
							<n-button
								type="primary" ghost size="large" :loading="loading" block @keydown.enter="handleSubmit"
								@click="handleSubmit"
							>
								登录
							</n-button>
						</NFormItem>
					</NForm>
				</div>
				<div v-else class="img_container">
					<div class="qrDiv">
						<n-image
							width="220"
							:src="qrUrl==''?wxLoginImg:qrUrl"
							:lazy="true"
							@click="getWxLoginQr"
							fit="cover"
							alt="点击重新加载二维码"
							title="点击重新加载二维码"
							preview-disabled
						/>
					</div>
					<p class="caption">
						{{ titleStr }}
					</p>

				</div>
				<NFormItem class="default-color" v-if="LoginFlag">
					<div class="flex view-account-other">
						<div class="flex-initial">
							<a href="javascript:" @click="handleForgotPwd()">忘记密码</a>
						</div>

						<div class="flex-initial" style="margin-left: auto">
							<a href="javascript:" @click="handleRegister()">注册账号</a>
						</div>
					</div>
				</NFormItem>
			</div>

			<!--	找回密码框	-->
			<div v-if="flag === '找回密码'" class="view-account-form">
				<NForm
					ref="formRef"
					label-placement="left"
					size="large"
					:model="formInline2"
					:rules="registerRules"
				>
					<NFormItem path="phone">
						<n-input
							v-model:value="formInline2.phone" placeholder="请输入手机号码" maxlength="11" show-count
							:allow-input="onlyAllowNumber" clearable
						>
							<template #prefix>
								<n-icon size="18" color="#808695">
									<PersonOutline/>
								</n-icon>
							</template>
						</n-input>
					</NFormItem>

					<NFormItem path="verifyCode">
						<n-input-group>
							<n-input
								v-model:value="formInline2.verifyCode" :style="{ width: '100%' }" maxlength="4"
								:allow-input="onlyAllowNumber"
							/>
							<n-button type="primary" ghost :disabled="remainTime > 0" @click="handleVerifyPhone">
								{{ remainTime > 0 ? `重新获取 (${remainTime}s)` : '获取验证码' }}
							</n-button>
						</n-input-group>
					</NFormItem>
					<NFormItem path="password">
						<n-input
							v-model:value="formInline2.password"
							minlength="6"
							maxlength="18"
							type="password"
							show-password-on="mousedown"
							placeholder="请输入新密码"
						>
							<template #prefix>
								<n-icon size="18" color="#808695">
									<LockClosedOutline/>
								</n-icon>
							</template>
						</n-input>
					</NFormItem>
					<NFormItem>
						<n-button
							type="primary" ghost size="large" :loading="loading" block @keydown.enter="handleForgotPwdSub"
							@click="handleForgotPwdSub"
						>
							重置密码
						</n-button>
					</NFormItem>
					<NFormItem class="default-color">
						<div class="flex view-account-other">
							<div class="flex-initial" style="margin-left: auto">
								<a href="javascript:" @click="handleLogin()">返回登录</a>
							</div>
						</div>
					</NFormItem>
				</NForm>
			</div>

			<!--	找回密码框	-->
			<div v-if="flag === '绑定手机号码'" class="view-account-form">
				<NForm
					ref="formRef"
					label-placement="left"
					size="large"
					:model="formInline2"
					:rules="registerRules"
				>
					<NFormItem path="phone">
						<n-input
							v-model:value="formInline2.phone" placeholder="请输入手机号码" maxlength="11" show-count
							:allow-input="onlyAllowNumber" clearable
						>
							<template #prefix>
								<n-icon size="18" color="#808695">
									<PersonOutline/>
								</n-icon>
							</template>
						</n-input>
					</NFormItem>

					<NFormItem path="verifyCode">
						<n-input-group>
							<n-input
								v-model:value="formInline2.verifyCode" :style="{ width: '100%' }" maxlength="4"
								:allow-input="onlyAllowNumber"
							/>
							<n-button type="primary" ghost :disabled="remainTime > 0" @click="handleVerifyPhone">
								{{ remainTime > 0 ? `重新获取 (${remainTime}s)` : '获取验证码' }}
							</n-button>
						</n-input-group>
					</NFormItem>
					<NFormItem path="password">
						<n-input
							v-model:value="formInline2.password"
							minlength="6"
							maxlength="18"
							type="password"
							show-password-on="mousedown"
							placeholder="请输入密码"
						>
							<template #prefix>
								<n-icon size="18" color="#808695">
									<LockClosedOutline/>
								</n-icon>
							</template>
						</n-input>
					</NFormItem>
					<NFormItem>
						<n-button
							type="primary" ghost size="large" :loading="loading" block @keydown.enter="bindPhone"
							@click="bindPhone"
						>
							完成绑定
						</n-button>
					</NFormItem>
				</NForm>
			</div>

			<div v-if="flag === '注册'" class="view-account-form">
				<NForm
					ref="formRef"
					label-placement="left"
					size="large"
					:model="formInline2"
					:rules="registerRules"
				>
					<NFormItem path="phone">
						<n-input
							v-model:value="formInline2.phone" placeholder="请输入手机号码" maxlength="11" show-count
							:allow-input="onlyAllowNumber" clearable
						>
							<template #prefix>
								<n-icon size="18" color="#808695">
									<PersonOutline/>
								</n-icon>
							</template>
						</n-input>
					</NFormItem>

					<NFormItem path="verifyCode">
						<n-input-group>
							<n-input
								v-model:value="formInline2.verifyCode" :style="{ width: '100%' }" maxlength="4"
								:allow-input="onlyAllowNumber"
							/>
							<n-button type="primary" ghost :disabled="remainTime > 0" @click="handleVerifyPhone">
								{{ remainTime > 0 ? `重新获取 (${remainTime}s)` : '获取验证码' }}
							</n-button>
						</n-input-group>
					</NFormItem>
					<NFormItem path="password">
						<n-input
							v-model:value="formInline2.password"
							minlength="6"
							maxlength="18"
							type="password"
							show-password-on="mousedown"
							placeholder="请输入密码"
						>
							<template #prefix>
								<n-icon size="18" color="#808695">
									<LockClosedOutline/>
								</n-icon>
							</template>
						</n-input>
					</NFormItem>

					<NFormItem>
						<n-button
							:disabled="!checked" type="primary" ghost size="large" :loading="loading" block
							@keydown.enter="handleRegisterSub" @click="handleRegisterSub"
						>
							注册
						</n-button>
					</NFormItem>
					<NFormItem class="default-color">
						<div class="flex view-account-other">
							<div class="flex-initial">
								<n-checkbox @update:checked="handleUpdateChecked"/>
								<a
									target="_blank" style="color:#006eff;"
									href="https://docs.qq.com/doc/DYmxlTmt2ZXNYaWN0"
								>&nbsp&nbsp免责声明</a>
							</div>

							<div class="flex-initial" style="margin-left: auto">
								<a href="javascript:" @click="handleLogin()">返回登录</a>
							</div>
						</div>
					</NFormItem>
					<NFormItem class="default-color">
						<p style="color: #999999;font-size: 8px;">
							注册前请先仔细阅读并理解本站免责申明，勾选视您已同意并遵守本站免责申明条款
						</p>
					</NFormItem>
				</NForm>
			</div>
		</div>
	</div>
</template>

<style lang="less" scoped>
.view-account-top {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 0;
	/* 设置为垂直方向排列 /
 text-align: center; / 设置文本居中 */
}

.view-account-top-logo {
	width: 4em;
	height: 4em;
	color: rgb(24, 160, 88);
}

.view-account-top-desc {
	margin-top: 10px; /* 调整与<svg>之间的距离 */
}

.view-account {
	display: flex;
	flex-direction: column;
	height: 100vh;
	overflow: auto;

	&-container {
		flex: 1;
		//padding: 10px 12px;
		// max-width: 384px;
		// min-width: 320px;
		margin: 0 auto;
	}

	&-top {
		padding: 10px 0px 10px 0px;
		text-align: center;

		&-desc {
			font-size: 14px;
			color: #808695;
		}
	}

	&-other {
		width: 100%;
	}

	.default-color {
		color: #515a6e;

		.ant-checkbox-wrapper {
			color: #515a6e;
		}
	}
}

@media (min-width: 768px) {
	.view-account {
		// background-image: url('../../assets/images/login.svg');
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: 100%;
	}

	.page-account-container {
		padding: 32px 0 24px 0;
	}
}

//.view-account-form {
//	border: limegreen solid 1px;
//}

.img_container {
	margin: 0px;
	display: flex;
	justify-content: center; /* 水平居中 */
	align-items: center; /* 垂直居中 */
}

.img_container img {
	max-width: 100%;
	max-height: 100%;
}

.qrDiv {
	position: relative;
	width: 230px;
	height: 230px;
	background: url(https://cdn.weijiwangluo.com/chat/qrcode-border.png);
	background-size: cover;
	margin: 30px auto 0;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0px 0px 10px 0px;
}

.caption {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: center;
	color: #999999;
	font-size: 6px;
}

.qrStyle {
	background-image: url(https://fecdn1.zhaopin.cn/passport_zhaopin_com/assets/ic_qrcode_hover@2x.baae8e.png);
	background-position: 0 0;
	background-size: cover;
	cursor: pointer;
	height: 58px;
	left: -10px;
	position: relative;
	top: -10px;
	width: 58px;
}

.pwdStyle {
	background-image: url(https://fecdn1.zhaopin.cn/passport_zhaopin_com/assets/ic_pc_hover@3x.1a4966.png);
	background-position: 0 0;
	background-size: cover;
	cursor: pointer;
	height: 58px;
	left: -10px;
	position: relative;
	top: -10px;
	width: 58px;
}

</style>
