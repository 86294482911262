<script setup lang="ts">
import axios from 'axios'
import Cookies from 'js-cookie'
import { useMessage } from 'naive-ui'
import type { NotificationType, UploadFileInfo } from 'naive-ui'
import { computed, onMounted, reactive, ref } from 'vue'
import { useAuthStore, useUserStore } from '@/store'
import { fetchChatConfig } from '@/api'
const emit = defineEmits(['closeSetting'])

const message = useMessage()

const userStore = useUserStore()
// const authStore = useAuthStore()

interface ConfigState {
  timeoutMs?: number
  reverseProxy?: string
  apiModel?: string
  socksProxy?: string
}
const notification = useNotification()
import {showLogin} from "@/hooks/LoginStatus";

const showModalRef = ref(false)
const previewImageUrlRef = ref('')
const userState = computed(() => userStore.userInfo)
const meta = ref<string>('')
const authStore = useAuthStore()
const previewFileList = ref<Array<UploadFileInfo>>([
  {
    id: 'vue',
    name: '我是vue.png',
    status: 'finished',
    url: 'https://07akioni.oss-cn-beijing.aliyuncs.com/07akioni.jpeg',
  },
])
let currentUserInfo = reactive({
  id: '',
  userId: '',
  userName: '',
  phone: '',
  userFlag: '',
  headImages: '',
  role: '',
})
const userInfoName = reactive({
  userName: '',
})

getUserInfoByLocalStroge()

function getUserInfoByLocalStroge() {
  const userInfo = localStorage.getItem('userInfo')
  if (userInfo) {
    currentUserInfo = JSON.parse(userInfo)
    // eslint-disable-next-line no-console
    // console.log(currentUserInfo)
    userState.value.name = currentUserInfo.userName
    userState.value.avatar = currentUserInfo.headImages
    userState.value.role = currentUserInfo.role
    const firstFile = previewFileList.value.at(0)
    if (firstFile)
      firstFile.url = currentUserInfo.headImages

    userInfoName.userName = currentUserInfo.userName
  }
}

const loading = ref(false)

const config = ref<ConfigState>()

function handlePreview(file: UploadFileInfo) {
  const { url } = file
  previewImageUrlRef.value = url as string
  showModalRef.value = true
}

async function fetchConfig() {
  try {
    loading.value = true
    const { data } = await fetchChatConfig<ConfigState>()
    config.value = data
  }
  finally {
    loading.value = false
  }
}

onMounted(() => {
  fetchConfig()
})

// 处理上传前的验证
const handleBeforeUpload = (file: File) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  const isLt2M = file.size / 1024 / 1024 < 2

  if (!isJpgOrPng) {
    message.error('只能上传 JPG/PNG 格式的图片')
    return false
  }

  if (!isLt2M) {
    message.error('图片大小不能超过 2MB')
    return false
  }
  return true
}

const token = Cookies.get('token')
const header = {
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${token}`,
  },
}

function handleUpload() {
  const token = Cookies.get('token')
  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${token}`,
    },
  }
  axios.post('/api/user/updateUserInfo', { userName: userInfoName.userName }, config)
    .then((response) => {
      const result = response.data
      if (result.code === 200) {
        console.log(result)

        getUserInfo()

        message.success('保存成功')
        emit('closeSetting')
      }
      else {
        message.warning('保存失败')
      }
    })
}

function notify(type: NotificationType) {
  notification[type]({
    content: '提示',
    meta: meta.value,
    duration: 2500,
    keepAliveOnHover: true,
  })
}

function getUserInfo() {
  const token = Cookies.get('token')

  const config = {
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  }
  axios.get('/api/user/getUserInfo', config)
    .then((response) => {
      const result = response.data
      if (result.code === 200) {
        const data = result.data
        userState.value.name = data.userName
        userState.value.avatar = data.headImages
        userState.value.role = currentUserInfo.role
        localStorage.setItem('userInfo', JSON.stringify(data))
      }
    }).catch((error: any) => {
      if (error.response) {
        // 请求发生错误，获取状态码
        if (error.response.status === 401) {
          meta.value = '当前已经掉线了，请重新登录'
          if (showLogin.value)
            notify('warning')

          authStore.removeToken()
          Cookies.remove('token')
					showLogin.value = true
        }
      }
    })
}
</script>

<template>
  <div class="p-4 space-y-5 min-h-[200px]">
    <div class="space-y-6">
      <div class="flex items-center space-x-4">
        <span class="flex-shrink-0 w-[100px]">头像</span>
        <div class="flex-1">
          <n-upload
            action="/api/user/pushPic"
            percentage
            :headers="header.headers"
            :max="1"
            name="headImage"
            :default-file-list="previewFileList"
            :before-upload="handleBeforeUpload"
            list-type="image-card"
            accept="image/*"
            :show-retry-button="true"
            :multiple="false"
            @preview="handlePreview"
          />
          <n-modal
            v-model:show="showModalRef"
            preset="card"
            style="width: 600px"
          >
            <img :src="previewImageUrlRef" style="width: 100%">
          </n-modal>
        </div>
      </div>
      <div class="flex items-center space-x-4">
        <span class="flex-shrink-0 w-[100px]">昵称</span>
        <div class="flex-1">
          <n-input v-model:value="userInfoName.userName" maxlength="10" show-count clearable />
        </div>
      </div>
      <div class="flex items-center space-x-4">
        <NButton type="warning" dashed @click="handleUpload">
          保存
        </NButton>
      </div>
    </div>
  </div>
</template>
