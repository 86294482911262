<script setup type="ts">
import { ref } from "vue";
let videoPath = reactive < String > "";
let isLooping = ref(false);
let isRefresh = ref(false);
let audioKey = ref(0);
const audio = ref();
const emit = defineEmits(["update:pathValue"]);
const props = defineProps({
  /**
   * 文件路径集合
   */
  pathValue: {
    type: String,
    default: "",
  },
});
watch(() => props.pathValue,
  (newVal) => {
     videoPath = newVal;
  },
  { immediate: true }
);
</script>
<style>
.audioBox{
  margin:0.3em;
  height: 2.5em;
  background-color: #f4f6f8;
  border-radius: 5px;
}
audio::-webkit-media-controls-panel {
  background-color: #f4f6f8;
  color: #fff;
  border-radius: 0px;
  /* Add more styles as needed */
}
</style>
<template>
  <div style="display:flex;align-items: center;justify-content:space-around">
    <audio controls  ref="audio" class="audioBox" style="width:100%;">
      <source :src="videoPath" />
    </audio>
  </div>

</template>
