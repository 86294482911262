import type { App } from 'vue'
import type { NavigationGuard, RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'
import { setupPageGuard } from './permission'
import { ChatLayout } from '@/views/chat/layout'

const requireAuth: NavigationGuard = (to, from, next) => {
  // const token = Cookies.get('token')
  // const config = {
  //   withCredentials: true,
  //   headers: {
  //     'content-type': 'application/json',
  //     'Authorization': `Bearer ${token}`,
  //   },
  // }
  // if (to.name !== 'Login') {
  //   axios.get('/api/user/getUserInfo', config)
  //     .then((response) => {
  //       const result = response.data
  //       if (result.code === 200)
  //         next()
  //       else
  //         next({ name: 'Login' })
  //     }).catch((error) => {
  //       console.log(error)
  //       next({ name: 'Login' })
  //     })
  // }
  // else {
  next()
  // }
}

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Root',
    component: ChatLayout,
    redirect: '/chat',
    children: [
      {
        path: '/chat/:uuid?',
        name: 'Chat',
        component: () => import('@/views/chat/index.vue'),
      },
      {
        path: '/draw',
        name: 'Draw',
        component: () => import('@/views/draw/index.vue'),
      },
			{
				path: '/artQR',
				name: 'artQR',
				component: () => import('@/views/artQR/index.vue'),
			},
			{
				path: '/easynav',
				name: 'Easynav',
				component: () => import('@/views/chat/easyNav.vue'),
			},
    ],
    beforeEnter: requireAuth, // 在进入该路由之前执行 requireAuth 守卫
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/chat/login.vue'),
  },


  {
    path: '/404',
    name: '404',
    component: () => import('@/views/exception/404/index.vue'),
  },

  {
    path: '/500',
    name: '500',
    component: () => import('@/views/exception/500/index.vue'),
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    redirect: '/404',
  },
]

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior: () => ({
    left: 0,
    top: 0,
  }),
})

setupPageGuard(router)

export async function setupRouter(app: App) {
  app.use(router)
  await router.isReady()
}
