import {ss} from '@/utils/storage'

const LOCAL_NAME = 'chatStorage'

export function defaultState(): Chat.ChatState {
	const uuid = Date.now()
	return {
		active: uuid,
		history: [{
			uuid,
			title: '新对话',
			isEdit: false,
			desc: null
		}],
		chat: [{
			uuid,
			data: []
		}],
		network: true
	}
}

export function getLocalState(): Chat.ChatState {
	const localState = ss.get(LOCAL_NAME)
	if (localState && localState.network === undefined) {
		localState.network = true
	}

	return localState ?? defaultState()
}

export function setLocalState(state: Chat.ChatState) {
	ss.set(LOCAL_NAME, state)
}
