<script setup lang="ts">
import Cookies from "js-cookie";
import {computed, ref} from 'vue'
import {NCard, NModal, NTabPane, NTabs} from 'naive-ui'
import General from './General.vue'
import About from './About.vue'
// import { useUserStore } from '@/store'
import {SvgIcon} from '@/components/common'

const props = defineProps<Props>()
const loginStatus = inject('showLogin');

const emit = defineEmits<Emit>()

// const userStore = useUserStore()

// const userState = computed(() => userStore.userInfo)

interface Props {
	visible: boolean
}

interface Emit {
	(e: 'update:visible', visible: boolean): void
}

const active = ref('General')
const token = Cookies.get('token')

const show = computed({
	get() {
		if (!token) {
			emit('update:visible', false)
			loginStatus.value = true
			return false
		}else{
			return props.visible
		}
	},
	set(visible: boolean) {
		emit('update:visible', visible)
	},
})

function handleClose() {
	show.value = false
}
</script>

<template>
	<NModal v-model:show="show" :auto-focus="false">
		<NCard role="dialog" aria-modal="true" :bordered="false" style="width: 95%; max-width: 640px">
			<NTabs v-model:value="active" type="line" animated>
				<NTabPane name="General" tab="General">
					<template #tab>
						<SvgIcon class="text-lg" icon="ri:file-user-line"/>
						<span class="ml-2">我的</span>
					</template>
					<div class="min-h-[100px]">
						<General/>
					</div>
				</NTabPane>
				<NTabPane name="Config" tab="Config">
					<template #tab>
						<SvgIcon class="text-lg" icon="ri:list-settings-line"/>
						<span class="ml-2">资料修改</span>
					</template>
					<About @closeSetting="handleClose()"/>
				</NTabPane>
			</NTabs>
		</NCard>
	</NModal>
</template>
