<script setup lang="ts">
// import VueMasonry from 'vue-masonry'
import {getPublicModelList, getPublicTemplate, getTaskList} from '@/api'
import defaultImg from '@/assets/loading.gif'
import defaultTemplate from '@/assets/qr-mewx-result.png'
import {showLogin} from "@/hooks/LoginStatus";
import {useBasicLayout} from '@/hooks/useBasicLayout'
import {useAppStore, useAuthStore} from '@/store'
import axios from '@/utils/request/axios'
import HeaderComponent from '@/views/chat/components/Header/index.vue'
import {EventSourcePolyfill} from 'event-source-polyfill'
import Cookies from 'js-cookie'
import {debounce} from 'lodash';
import type {DrawerPlacement, UploadFileInfo} from 'naive-ui'
import {NSlider, useMessage} from 'naive-ui'
import {v4 as uuidv4} from 'uuid'
import {reactive, ref, watch} from 'vue'

const {isMobile} = useBasicLayout()
const appStore = useAppStore()

// defineComponent({
//   components: {
//     // eslint-disable-next-line vue/no-unused-components
//     VueMasonry,
//   },
// })
interface drawConfig {
	artQrPrompt: string
	template_id: string
	seed: number
	qr_content: string
	abstract: number
	qr_image: string
	model: number
	originalPrompt: string
}

const drawState: drawConfig = reactive({
	artQrPrompt: '',
	template_id: '',
	qr_content: '',
	seed: 495826542,
	abstract: 0.45,
	qr_image: '',
	drawPrompt: '',
	model: -1,
	originalPrompt: ''
})
const showModalRef = ref(false)
const previewImageUrlRef = ref('')
const base64 = ref('')

const showModal = showModalRef.value
const previewImageUrl = previewImageUrlRef.value

function handlePreview(file: UploadFileInfo) {
	const {url} = file
	previewImageUrlRef.value = url as string
	showModalRef.value = true
}

function handleRemove() {
	base64.value = ''
}

function handleGetBase64(file: UploadFileInfo[]) {
	if (file.length > 0) {
		let fileData = file[0].file;
		var reader = new FileReader()
		if (fileData != null) {
			reader.readAsDataURL(fileData)
			reader.onload = () => {
				const base64String = reader.result as string;
				base64.value = base64String.replace(/^data:image\/[a-z]+;base64,/, '');
			}
			reader.onerror = function (error) {
				console.log('Error: ', error)
			}
		}
	}
}

const previewFileList = ref<UploadFileInfo[]>([])

const abstract = {
	0.45: '0.45',
}
const seed = {
	495826542: '495826542',
}
const art = {
	300: '300',
}

const authStore = useAuthStore()

const chooseStyleFlag = ref<boolean>(true)

// 图片比例
const checkRatio = ref<'1:1' | '4:3' | '3:4' | '16:9' | '9:16'>('1:1')

// 选择模型
const checkModel = ref<'1' | '1.1' | '2'>('1.1')
//1生成用模板 2自定义生成
const generateType = ref<number>(2)

// 选择款式
const chooseStyle = ref<'template' | 'model'>('model')

function handleUpdateStyle(newTab) {
	if (newTab === 'template') {
		chooseStyle.value = 'template'
		generateType.value = 1
		drawState.model = -1
		drawState.abstract = 0.45
		drawState.originalPrompt = ''

	} else if (newTab === 'model') {
		chooseStyle.value = 'model'
		generateType.value = 2
		drawState.model = -1
		drawState.abstract = 0.45
		drawState.originalPrompt = ''
		selectedTemplateIndex.value = -1; // 取消选中状态
	}
}

watch(chooseStyle, (newValue) => {
	if (newValue === 'template') {
		chooseStyleFlag.value = false
	} else {
		chooseStyleFlag.value = true
	}
});
watch(
	isMobile,
	(val) => {
		appStore.setSiderCollapsed(val)
	},
	{
		immediate: true,
		flush: 'post',
	},
)
const eventSource = ref<EventSourcePolyfill>()
const taskId = ref<string>('')
const meta = ref<string>('')
const params = ref<string>('')
const active = ref(false)
const placement = ref<DrawerPlacement>('right')
const activate = (place: DrawerPlacement) => {
	active.value = true
	placement.value = place
}
const showProgress = ref<boolean>(false)
const showOperateDiv = ref<boolean>(false)
const page = ref<number>(1)
const total = ref<number>(0)
// 用户公开作品总数
const imgTotal = ref(0);
// 用户个人作品总数
const userImgTotal = ref(0);
const userPage = ref<number>(1)
const userTotal = ref<number>(0)
// 显示登录提示模态框
const picUrl = ref<string>('')
const message = useMessage()
// 用户公开作品图像列表
const imgList = ref<any>([])
// 用户公开作品图像列表
const userImgList = ref<any>([])
// 是否正在加载用户公开图像列表
const isLoading = ref<boolean>(false)
// 是否正在加载用户私有图像列表
const isUserLoading = ref<boolean>(false)
// 用户公开图像列表当前页码
const imgPageNum = ref(1)
// 用户公开图像列表当前页容量
const imgPageSize = ref(9)

//模型图片框样式
const itemClass = ref<string>('')

// 用户图像列表当前页码
const userImgPageNum = ref(1)
// 用户图像列表当前页容量
const userImgPageSize = ref(9)
// 轮播图图片列表
const carouselImgList = ref([]);
const progress = ref('')

// 获取轮播图图片列表
function getCarouselList() {
	const params = {
		currentPage: 1,
		pageSize: 9,
		queryString: '',
	}
	getPublicTemplate(params).then((res) => {
		if (res.data.code === 200) {
			carouselImgList.value = res.data.data.records
		}
	})
}

getPublicModel()

// 公开的模型
const publicModelList = ref<any>([])

// 获取公开的模型
function getPublicModel() {
	isLoading.value = true
	const params = {
		currentPage: imgPageNum.value,
		pageSize: imgPageSize.value,
		queryString: '',
	}
	getPublicModelList(params).then((res) => {
		if (res.data.code === 200) {
			publicModelList.value = res.data.data.records
		}
	})
}

// 获取用户公开作品图像列表
function getImgList() {
	isLoading.value = true
	const params = {
		currentPage: imgPageNum.value,
		pageSize: imgPageSize.value,
		queryString: '',
	}
	getPublicTemplate(params).then((res) => {
		if (res.data.code === 200) {
			imgList.value = res.data.data.records
			total.value = res.data.data.pages
			imgTotal.value = res.data.data.total
			isLoading.value = false
		}
	})
}

const selectedIndex = ref(-1)
const selectedTemplateIndex = ref(-1)

function handleItemClick(index, value) {
	if (selectedIndex.value === index) {
		selectedIndex.value = -1; // 取消选中状态
		drawState.model = -1
	} else {
		selectedIndex.value = index; // 设置选中状态
		drawState.model = value
	}
}

function handleItemTemplateClick(index, value) {
	if (selectedTemplateIndex.value === index) {
		selectedTemplateIndex.value = -1; // 取消选中状态
		drawState.template_id = ''
	} else {
		selectedTemplateIndex.value = index; // 设置选中状态
		drawState.template_id = value
	}
}

// 获取用户自己的作品图像列表
function getUserImgList() {
	isUserLoading.value = true
	const params = {
		currentPage: userImgPageNum.value,
		pageSize: userImgPageSize.value,
		queryString: '',
	}
	getTaskList(params).then((res) => {
		if (res.data.code === 200) {
			console.log(res.data.data.records.length)
			userImgList.value = res.data.data.records
			userTotal.value = res.data.data.pages
			userImgTotal.value = res.data.data.total
			isUserLoading.value = false
		}
	})
}

getCarouselList()
getImgList()
getUserImgList()

const isEnabledTranslate = ref<boolean>(false)

function handleTranslate() {
	if (drawState.originalPrompt.trim().length === 0) {
		return
	}
	isEnabledTranslate.value = true
	isEnabledHzy.value = true
	const clientId = uuidv4()
	const token = Cookies.get('token')
	const config = {
		automaticReconnection: false,
		withCredentials: true,
		headers: {
			'content-type': 'application/json',
			'Authorization': `Bearer ${token}`,
			'clientid': clientId,
		},
	}
	eventSource.value = new EventSourcePolyfill(
		'/api/clientRegister',
		config,
	)

	if (eventSource.value instanceof EventSourcePolyfill) {
		eventSource.value.onopen = (event: { target: any }) => {
			console.log('已连接到服务器 -> 等待响应')

			axios.post('/toConversationByDraw',
				{
					prompt: `你将充当一个专业的翻译官，根据我的输入简单的翻译为英文，并直接输出翻译结果,需要使用 ',' 隔开\nuser:一个小女孩\nai:1girl,\nuser:一个小男孩\nai:1boy,\nuser:一个小女孩和一个小男孩\nai:1girl,1boy,\nuser:${drawState.originalPrompt}\nai:`,
					sessionId: clientId,
					network: false,
					withoutContext: true,
					system: '',
				}, config)
			drawState.artQrPrompt = ''
		}
	}

	if (eventSource.value instanceof EventSourcePolyfill) {
		eventSource.value.onerror = (event: any) => {
			const status = event.status
			if (status === 401) {
				message.info('请登录后再使用本功能')
				authStore.removeToken()
				Cookies.remove('token')
				showLogin.value = true
			}
			isEnabledTranslate.value = false
			isEnabledHzy.value = false

		}
	}

	const text = ''

	if (eventSource.value instanceof EventSourcePolyfill) {
		eventSource.value.onmessage = (event: {
			lastEventId: string
			data: string
		}) => {
			if (event.lastEventId === '[TOKENS]') {
				drawState.artQrPrompt = text + event.data
				// setText(text, uuid_str)
				// text = ''
				return
			}
			if (event.data === '[DONE]') {
				if (eventSource.value) {
					if (eventSource.value instanceof EventSourcePolyfill) {
						eventSource.value.close()
					}
				}
				isEnabledTranslate.value = false
				isEnabledHzy.value = false
				return
			}

			const json_data = JSON.parse(event.data)
			if (json_data.content == null || json_data.content === 'null') {
				return
			}

			drawState.artQrPrompt += json_data.content
		}
	}
}

const isEnabledHzy = ref<boolean>(false)

function handleAiOptimize() {
	if (drawState.originalPrompt.trim().length === 0) {
		message.info("请输入你的绘画灵感，再翻译为英文")
		return
	}
	isEnabledHzy.value = true
	isEnabledTranslate.value = true
	hzy()
}

function hzy() {
	const clientId = uuidv4()
	const token = Cookies.get('token')
	const config = {
		automaticReconnection: false,
		withCredentials: true,
		headers: {
			'content-type': 'application/json',
			'Authorization': `Bearer ${token}`,
			'clientid': clientId,
		},
	}
	eventSource.value = new EventSourcePolyfill(
		'/api/clientRegister',
		config,
	)

	if (eventSource.value instanceof EventSourcePolyfill) {
		eventSource.value.onopen = (event: { target: any }) => {
			console.log('已连接到服务器 -> 等待响应')

			axios.post('/toConversationByDraw',
				{
					prompt: `你需要为我生成Midjourney绘画提示词，回答的形式是：\n(image we're prompting), (7 descriptivekeywords), (camera type), (camera lenstype), (time of day), (style of photograph)(type of film), (Hd degree).\n这是一段段按照上述形式的示例问答：\n问题：\n参考以上midjoruney prompt formula写1个midjourney prompt内容，用英文回复，不要括号，内容：宫崎骏风格的春天小镇\n回答：\n英文：Miyazaki Hayao-style town,Green willow and red flowers, breeze coming, dreamy colors, fantastic elements, fairy-tale situation, warm breath, smooth picture, telephoto lens, shooting in the evening, positive film, 4K ultra HD\n现在严格参考以上的示例回答形式和风格（这很重要），根据以下的内容生成提示词(直接以英文输出，需要补全):${drawState.originalPrompt}`,
					sessionId: clientId,
					network: false,
					withoutContext: true,
					system: 'I want you to act as a prompt generator for Midjourney\'s artificial intelligence program. Your job is to provide detailed and creative descriptions that will inspire unique and interesting images from the AI. Keep in mind that the AI is capable of understanding a wide range of language and can interpret abstract concepts, so feel free to be as imaginative and descriptive as possible.',
				}, config)
			drawState.artQrPrompt = ''
		}
	}

	if (eventSource.value instanceof EventSourcePolyfill) {
		eventSource.value.onerror = (event: any) => {
			const status = event.status
			if (status === 401) {
				message.info('请登录后再使用本功能')
				authStore.removeToken()
				Cookies.remove('token')
				showLogin.value = true
			}
			isEnabledHzy.value = false
			isEnabledTranslate.value = false
		}
	}

	const text = ''

	if (eventSource.value instanceof EventSourcePolyfill) {
		eventSource.value.onmessage = (event: {
			lastEventId: string
			data: string
		}) => {
			if (event.lastEventId === '[TOKENS]') {
				drawState.artQrPrompt = text + event.data
				// setText(text, uuid_str)
				// text = ''
				return
			}
			if (event.data === '[DONE]') {
				if (eventSource.value) {
					if (eventSource.value instanceof EventSourcePolyfill) {
						eventSource.value.close()
					}
				}
				isEnabledHzy.value = false
				isEnabledTranslate.value = false
				yyz()
				return
			}

			const json_data = JSON.parse(event.data)
			if (json_data.content == null || json_data.content === 'null') {
				return
			}
			drawState.artQrPrompt += json_data.content
		}
	}
}

function yyz() {
	const clientId = uuidv4()
	const token = Cookies.get('token')
	const config = {
		automaticReconnection: false,
		withCredentials: true,
		headers: {
			'content-type': 'application/json',
			'Authorization': `Bearer ${token}`,
			'clientid': clientId,
		},
	}
	eventSource.value = new EventSourcePolyfill(
		'/api/clientRegister',
		config,
	)

	if (eventSource.value instanceof EventSourcePolyfill) {
		eventSource.value.onopen = (event: { target: any }) => {
			console.log('已连接到服务器 -> 等待响应')

			axios.post('/toConversationByDraw',
				{
					prompt: `请您帮我将这段内容翻译为中文，并不要做任何解释，只给我输出中文：${drawState.artQrPrompt}`,
					sessionId: clientId,
					network: false,
					withoutContext: true,
					system: '您现在是一名翻译',
				}, config).then((response) => {
				const result = response.data
				if (result.code === 200) {
					isEnabledHzy.value = true
					isEnabledTranslate.value = true
				}
			}).catch((error) => {
				isEnabledHzy.value = false
				isEnabledTranslate.value = false
			})
			drawState.originalPrompt = ''
		}
	}

	if (eventSource.value instanceof EventSourcePolyfill) {
		eventSource.value.onerror = (event: any) => {
			const status = event.status
			if (status === 401) {
				message.info('请登录后再使用本功能')

				authStore.removeToken()
				Cookies.remove('token')
				showLogin.value = true
			}
			event.target.close();
		}
	}

	const text = ''

	if (eventSource.value instanceof EventSourcePolyfill) {
		eventSource.value.onmessage = (event: {
			lastEventId: string
			data: string
		}) => {
			if (event.lastEventId === '[TOKENS]') {
				drawState.originalPrompt = text + event.data
				// setText(text, uuid_str)
				// text = ''
				return
			}
			if (event.data === '[DONE]') {
				if (eventSource.value) {
					eventSource.value.close()
				}
				isEnabledHzy.value = false
				isEnabledTranslate.value = false
				return
			}

			const json_data = JSON.parse(event.data)
			if (json_data.content == null || json_data.content === 'null') {
				return
			}
			drawState.originalPrompt += json_data.content
		}
	}
}

function handleDel(id: string) {
	const token = Cookies.get('token')
	const config = {
		withCredentials: true,
		headers: {
			'content-type': 'application/json',
			'Authorization': `Bearer ${token}`,
		},
	}
	axios.delete(`/artQR/deleteQr/${id}`, config)
		.then((response) => {
			const result = response.data
			console.log(result)
			if (result.data === 200) {
				message.success("删除成功")
				getPrivatePic()
			}

		}).catch((error) => {
		message.error("删除失败，请稍后再试")
		console.log(error)
	})
}

const condition = ref(false);
const hasRunTask = ref(false);

const handleRunArtQR = debounce(() => {
	// 在这里编写需要进行防抖的代码
	handleRunDraw()
}, 1000); // 设置防抖的延迟时间，单位为毫秒

function handleRunDraw() {

	// 提取 --v 的参数 版本
	if (checkModel.value === "1") {
		params.value += ` --v 1`
	} else if (checkModel.value === "1.1") {
		params.value += ` --v 1.1`
	} else {
		params.value += ` --v 2`
	}

	// 二维码强度 --iw
	params.value += ` --iw ${drawState.abstract}`

	//自定义模式必须选择模型
	if (generateType.value === 2) {
		if (drawState.model === -1) {
			message.warning("请选择模型")
			return;
		}
		if (drawState.artQrPrompt === "") {
			message.warning("请输入英文咒语")
			return;
		}
		if (base64.value === "" && drawState.qr_content == "") {
			message.warning("请上传二维码或输入展示内容")
			return;
		}
	} else {
		if (base64.value === "" && drawState.qr_content == "") {
			message.warning("请上传二维码或输入展示内容")
			return;
		}
	}
	activate('right')
	const token = Cookies.get('token')
	const config = {
		withCredentials: true,
		headers: {
			'content-type': 'application/json',
			'Authorization': `Bearer ${token}`,
		},
	}
	axios.post('/artQR/generateQR',
		{
			generateType: generateType.value,
			prompt: `${drawState.artQrPrompt}${params.value}`,
			template_id: drawState.template_id,
			qr_content: drawState.qr_content,
			qr_image: base64.value,
			model: drawState.model,
		}, config)
		.then((response) => {
			const result = response.data
			console.log(result.code)
			if (result.code === 200) {
				//暂无权限使用
				if (result.data === 10010) {
					message.warning(result.msg)
					return
				}
				//暂无权限使用
				if (result.data === 40089) {
					message.warning(result.msg)
					return
				}
				resetDivStatus()
				hasRunTask.value = true
				taskId.value = result.data
				console.log(`任务创建成功：${result.data}`)
				showOperateDiv.value = false
				showProgress.value = true
				condition.value = false
				drawState.artQrPrompt = ""
				drawState.qr_content = ""
				base64.value = ''
				params.value = ''
				getUserImgList()
				let timer;
				timer = setInterval(() => {
					// 每5秒执行一次的逻辑
					axios.get(`/artQR/getTaskDetail/${taskId.value}?date=${Date.now()}`, config)
						.then((response) => {
							const result = response.data
							if (result.code === 200) {
								if (result.data.imageUrl) {
									condition.value = true
								}
								picUrl.value = !result.data.imageUrl ? defaultTemplate : (result.data.imageUrl + '?' + new Date().getTime());
								currentStep.value = 2;
							}
							if (result.code === 40057) {
								message.error('获取任务状态失败，请稍后再试')
								condition.value = true
								currentStep.value = 1;
							}
							if (result.data === 40258) {
								currentStep.value = 1;
							}
						}).catch((error) => {
						console.log(error)
						message.warning("获取状态失败，请稍后到我的创作中查看")
						hasRunTask.value = false
						currentStep.value = 1;
					})

					if (condition.value) {
						clearInterval(timer);
					}
				}, 5000);
			}
			if (result.code == 400) {
				message.warning(result.msg)
			}
		}).catch((error) => {
		const result = error.response
		params.value = ""
		console.log(result)
		if (result.status === 400) {
			message.warning(result.msg)
			return;
		}
		if (result.status === 401) {
			message.info('请登录后再使用本功能')
			authStore.removeToken()
			Cookies.remove('token')
			showLogin.value = true
		}
	})

}

const drawConfigDiv = ref(true)

function resetDivStatus() {
	drawConfigDiv.value = !drawConfigDiv.value
}

// 获取公开图片列表
function getPublicPic() {
	const param = {
		currentPage: page.value,
		pageSize: 9,
		queryString: '',
	}
	isLoading.value = true
	getPublicTemplate(param).then((res) => {
		if (res.data.code === 200) {
			imgList.value = res.data.data.records
			total.value = res.data.data.pages
			isLoading.value = false
		}
	})
}

// 获取私有图片列表
function getPrivatePic() {
	const param = {
		currentPage: userPage.value,
		pageSize: 9,
		queryString: '',
	}
	isUserLoading.value = true
	getTaskList(param).then((res) => {
		if (res.data.code === 200) {
			// const start = (imgPageNum.value - 1) * imgPageSize.value
			// const end = start + imgPageSize.value
			// imgList.value = imgList.value.concat(res.data.data.records.slice(start, end))
			userImgList.value = res.data.data.records
			userTotal.value = res.data.data.pages
			isUserLoading.value = false
		}
	})
}

const currentStep = ref<number | undefined>(1)
</script>

<template>
	<HeaderComponent v-if="isMobile" />
	<div class="draw-box bg-neutral-100" :class="[isMobile ? '' : 'flex']">
		<div :class="[isMobile ? '' : 'draw-list']">
			<n-tabs type="segment" default-value="chap1" v-if="!isMobile">
				<n-tab-pane name="chap1" tab="公开模板" style="display: flex;flex-direction: column;height: 100%;">
						<div class="masonry">
							<div v-for="(item, index)  in imgList" :key="item.id" class="masonry-item"
									 @click="handleItemTemplateClick(index,item.id)"
									 :class="{'model_grid': selectedTemplateIndex === index}"
							>
								<!-- 展示列表项的内容 -->
								<div>
									<n-image
										style="border-radius: 10px;"
										:title="item.name"
										width="300"
										height="100%"
										:src="item.previewImg"
									/>
									<p class="model_title">{{ item.name }}</p>
									<div class="img-bottom" v-show="chooseStyleFlag">
									</div>
								</div>
							</div>

						</div>
						<div v-if="isLoading" class="loading">
							Loading...
						</div>
						<n-pagination
							v-if="imgList.length!=0"
							v-model:page="page"
							class="pageClass"
							:page-count="total"
							simple
							@update:page="getPublicPic()"
						/>
				</n-tab-pane>
				<n-tab-pane name="chap2" tab="我的创作">
						<div class="masonry">
							<div v-for="item in userImgList" :key="item.id" class="masonry-item">
								<!-- 展示列表项的内容 -->
								<n-image
									style="border-radius: 10px;"
									:title="item.id"
									width="300"
									height="100%"
									:src="item.url===null || item.url===''? defaultImg :item.url"
								/>
								<div class="img-bottom">
									<n-button tertiary size="tiny" type="error" @click="handleDel(item.id)">
										删除
									</n-button>
								</div>
							</div>
						</div>
						<div v-if="isUserLoading" class="loading">
							Loading...
						</div>
						<n-pagination
							v-if="userImgList.length!=0"
							v-model:page="userPage"
							class="pageClass"
							:page-count="userTotal"
							simple
							@update:page="getPrivatePic()"
						/>
				</n-tab-pane>
			</n-tabs>
			<div v-if="isMobile">
				<n-carousel autoplay style="margin-bottom:1em">
					<img class="carousel-img" v-for="item in carouselImgList" :key="item.id" :src="item.previewImg"/>
				</n-carousel>
				<n-collapse>
					<n-collapse-item name="1">
						<template #header>
							<svg t="1689002835584" class="icon" viewBox="0 0 1024 1024" version="1.1"
									 xmlns="http://www.w3.org/2000/svg" p-id="1976" width="16" height="16"
							>
								<path
									d="M512 64.47C264.83 64.47 64.47 264.83 64.47 512S264.83 959.53 512 959.53 959.53 759.17 959.53 512 759.17 64.47 512 64.47zM758.14 696.8c0 48.73-40.06 88.22-89.52 88.22s-89.49-39.49-89.49-88.22c0-0.31 0.07-0.65 0.1-1s-0.1-0.65-0.1-1a9.49 9.49 0 0 1 0.14-1.43L421.63 579.19a89.62 89.62 0 0 1-66.24 29.38c-49.47 0-89.53-39.52-89.53-88.26 0-0.3 0.09-0.61 0.09-0.95s-0.09-0.65-0.09-1c0-48.74 40.06-88.23 89.53-88.23a89.85 89.85 0 0 1 62.82 25.46l163.62-106.47a81.67 81.67 0 0 1-2.73-20c0-0.24 0.07-0.51 0.07-0.82s-0.07-0.71-0.07-1.12c0-48.74 40.07-88.23 89.49-88.23s89.52 39.49 89.52 88.23c0 0.3-0.1 0.61-0.1 1s0.1 0.65 0.1 1c0 48.74-40.06 88.26-89.52 88.26a89.63 89.63 0 0 1-64.82-27.71L441.28 495.46a83.45 83.45 0 0 1 3.59 22.94c0 0.37-0.07 0.71-0.07 1.09s0.07 0.55 0.07 0.85a84.46 84.46 0 0 1-2.39 18.94l150.44 109a89.46 89.46 0 0 1 75.7-41.64c49.46 0 89.52 39.52 89.52 88.26 0 0.28-0.1 0.65-0.1 1s0.1 0.56 0.1 0.9z"
									fill="#48E1AA" p-id="1977"
								></path>
								<path
									d="M959.53 512a445.88 445.88 0 0 0-40.83-187 452.1 452.1 0 0 0-302.9-115.9C365.17 209.1 162 412.27 162 662.89a452.78 452.78 0 0 0 29.61 161.58A446.22 446.22 0 0 0 512 959.53c247.17 0 447.53-200.36 447.53-447.53z m-604.14 96.57c-49.47 0-89.53-39.52-89.53-88.26 0-0.3 0.09-0.61 0.09-0.95s-0.09-0.65-0.09-1c0-48.74 40.06-88.23 89.53-88.23a89.85 89.85 0 0 1 62.82 25.46l163.62-106.47a81.67 81.67 0 0 1-2.73-20c0-0.24 0.07-0.51 0.07-0.82s-0.07-0.71-0.07-1.12c0-48.74 40.07-88.23 89.49-88.23s89.52 39.49 89.52 88.23c0 0.3-0.1 0.61-0.1 1s0.1 0.65 0.1 1c0 48.74-40.06 88.26-89.52 88.26a89.63 89.63 0 0 1-64.82-27.71L441.28 495.46a83.45 83.45 0 0 1 3.59 22.94c0 0.37-0.07 0.71-0.07 1.09s0.07 0.55 0.07 0.85a84.46 84.46 0 0 1-2.39 18.94l150.44 109a89.46 89.46 0 0 1 75.7-41.64c49.46 0 89.52 39.52 89.52 88.26 0 0.28-0.1 0.65-0.1 1s0.1 0.65 0.1 1c0 48.73-40.06 88.22-89.52 88.22s-89.49-39.49-89.49-88.22c0-0.31 0.07-0.65 0.1-1s-0.1-0.65-0.1-1a9.49 9.49 0 0 1 0.14-1.43L421.63 579.19a89.62 89.62 0 0 1-66.24 29.38z"
									fill="#3CD38E" p-id="1978"
								></path>
								<path
									d="M668.59 417.41a89.57 89.57 0 0 1-64.17-27.06l-2.55 0.58-160.59 104.53a83.45 83.45 0 0 1 3.59 22.94c0 0.37-0.07 0.71-0.07 1.09s0.07 0.55 0.07 0.85a84.46 84.46 0 0 1-2.39 18.94l150.44 109a89.46 89.46 0 0 1 75.7-41.64c49.46 0 89.52 39.52 89.52 88.26 0 0.28-0.1 0.65-0.1 1s0.1 0.65 0.1 1c0 48.73-40.06 88.22-89.52 88.22s-89.49-39.49-89.49-88.22c0-0.31 0.07-0.65 0.1-1s-0.1-0.65-0.1-1a9.49 9.49 0 0 1 0.14-1.43L421.63 579.19a89.62 89.62 0 0 1-66.24 29.38 90.89 90.89 0 0 1-24.19-3.26 405.87 405.87 0 0 0-22 305.75A445.67 445.67 0 0 0 512 959.53c247.17 0 447.53-200.36 447.53-447.53q0-16.68-1.21-33.07A402.84 402.84 0 0 0 739.51 383a89.81 89.81 0 0 1-70.92 34.41z"
									fill="#1DCE75" p-id="1979"
								></path>
								<path
									d="M945.57 623.35a406.86 406.86 0 0 0-241.14-9.42c31.62 13.63 53.71 44.73 53.71 80.92 0 0.28-0.1 0.65-0.1 1s0.1 0.65 0.1 1c0 48.73-40.06 88.22-89.52 88.22s-89.49-39.49-89.49-88.22c0-0.31 0.07-0.65 0.1-1s-0.1-0.65-0.1-1a9.49 9.49 0 0 1 0.14-1.43l-14.6-10.59a404.3 404.3 0 0 0-155.35 264.86A448.85 448.85 0 0 0 512 959.53c208.72 0 384.07-142.89 433.57-336.18z"
									fill="#05C46D" p-id="1980"
								></path>
								<path
									d="M411.22 151.84c7.56 11.35-32.21 25.37-74.16 53.32s-70.2 59.25-77.76 47.91 15.63-50.24 57.58-78.19 86.78-34.39 94.34-23.04z"
									fill="#FFFFFF" p-id="1981"
								></path>
							</svg>
							<n-text style="margin-left: 0.5em" type="success">
								公开模板
							</n-text>
						</template>
						<template #header-extra>
							<n-text type="success">
								作品总数：{{ imgTotal }}
							</n-text>
						</template>
						<div>
							<div class="masonry">
								<div v-for="(item, index)  in imgList" :key="item.id" class="masonry-item"
										 @click="handleItemTemplateClick(index,item.id)"
										 :class="{'model_grid': selectedTemplateIndex === index}"
								>
									<!-- 展示列表项的内容 -->
									<div>
										<n-image
											style="border-radius: 10px;"
											:title="item.name"
											height="100%"
											:src="item.previewImg"
										/>
										<p class="model_title">{{ item.name }}</p>
										<div class="img-bottom" v-show="chooseStyleFlag">
										</div>
									</div>
								</div>
							</div>
							<div v-if="isLoading" class="loading">
								Loading...
							</div>
							<n-pagination
								v-if="imgList.length!=0"
								v-model:page="page"
								class="pageClass"
								:page-count="total"
								simple
								@update:page="getPublicPic()"
							/>
						</div>
					</n-collapse-item>
					<n-collapse-item name="2">
						<template #header>
							<svg t="1689002736286" class="icon" viewBox="0 0 1024 1024" version="1.1"
									 xmlns="http://www.w3.org/2000/svg" p-id="1488" width="16" height="16"
							>
								<path
									d="M506.72 63.78c-204.2 2.84-368.35 171.39-366 375.6A370.45 370.45 0 0 0 267 714.08a1.91 1.91 0 0 0 0.8 0.41 1.8 1.8 0 0 0-0.39 1.11c0 135.11 109.53 244.64 244.64 244.64s244.59-109.51 244.59-244.62a1.8 1.8 0 0 0-0.39-1.11 1.91 1.91 0 0 0 0.8-0.41 370.44 370.44 0 0 0 126.29-279c0-206.86-169.11-374.2-376.62-371.32zM461 505.9l0.47-1.21h0.58z m194.42-104.48L482.66 646.16a8.63 8.63 0 0 1-6.3 2.7 3.75 3.75 0 0 1-2.7-0.9c-4.2-1.22-5.7-3.6-4.5-7.2l27.9-164.66H375.58q-4.49 0-6.3-3.6-2.68-3.57 0.91-8.1l187.15-244.74c2.4-3.6 5.1-4.18 8.1-1.8 3 0.6 4.5 3 4.5 7.2L542 389.72h107.12c3 0 5.1 1.22 6.3 3.6a18.4 18.4 0 0 1 0 8.1z"
									fill="#48E1AA" p-id="1489"
								></path>
								<path
									d="M815.42 221a460.67 460.67 0 0 0-185-38.5c-235 0-429 175.32-458.38 402.28A372.57 372.57 0 0 0 267 714.08a1.91 1.91 0 0 0 0.8 0.41 1.8 1.8 0 0 0-0.39 1.11c0 135.11 109.53 244.64 244.64 244.64s244.59-109.51 244.59-244.62a1.8 1.8 0 0 0-0.39-1.11 1.91 1.91 0 0 0 0.8-0.41A371.39 371.39 0 0 0 815.42 221zM461 505.9l0.47-1.21h0.58z m194.42-104.48L482.66 646.16a8.63 8.63 0 0 1-6.3 2.7 3.75 3.75 0 0 1-2.7-0.9c-4.2-1.22-5.7-3.6-4.5-7.2l27.9-164.66H375.58q-4.49 0-6.3-3.6-2.68-3.57 0.91-8.1l187.15-244.74c2.4-3.6 5.1-4.18 8.1-1.8 3 0.6 4.5 3 4.5 7.2L542 389.72h107.12c3 0 5.1 1.22 6.3 3.6a18.4 18.4 0 0 1 0 8.1z"
									fill="#3CD38E" p-id="1490"
								></path>
								<path
									d="M687.77 391.85q-16.38 0-32.43 1.34a0.57 0.57 0 0 1 0.08 0.13 18.4 18.4 0 0 1 0 8.1L482.66 646.16a8.63 8.63 0 0 1-6.3 2.7 3.75 3.75 0 0 1-2.7-0.9c-4.2-1.22-5.7-3.6-4.5-7.2l27.9-164.66h-51.88c-90.64 71.68-148.81 182.62-148.81 307.14a393.7 393.7 0 0 0 3.82 54.85A244.54 244.54 0 0 0 512 960.26c135.11 0 244.64-109.53 244.64-244.64a1.8 1.8 0 0 0-0.39-1.11 1.91 1.91 0 0 0 0.8-0.41 370.43 370.43 0 0 0 126.18-270 389.59 389.59 0 0 0-195.46-52.25zM461 505.9l0.47-1.21h0.58z"
									fill="#1DCE75" p-id="1491"
								></path>
								<path
									d="M788.81 572.1c-178.69 0-323.55 144.9-323.55 323.54a324.69 324.69 0 0 0 5.81 61.19 246.45 246.45 0 0 0 40.93 3.43c135.11 0 244.64-109.53 244.64-244.64a1.8 1.8 0 0 0-0.39-1.11 1.91 1.91 0 0 0 0.8-0.41 372.35 372.35 0 0 0 97.45-135.3 324.55 324.55 0 0 0-65.69-6.7z"
									fill="#05C46D" p-id="1492"
								></path>
								<path
									d="M411.17 152.4c7.56 11.34-32.19 25.35-74.11 53.29s-70.16 59.22-77.72 47.88 15.66-50.21 57.56-78.14 86.72-34.37 94.27-23.03z"
									fill="#FFFFFF" p-id="1493"
								></path>
							</svg>
							<n-text style="margin-left: 0.5em" type="success">
								我的二维码
							</n-text>
						</template>
						<template #header-extra>
							<n-text type="success">
								创作总数：{{ userImgTotal }}
							</n-text>
						</template>
						<div>
							<div class="masonry">
								<div v-for="item in userImgList" :key="item.id" class="masonry-item-mobile">
									<!-- 展示列表项的内容 -->
									<img :title="item.id" style="height: 100%"
											 :src="item.url===null || item.url===''? defaultImg :item.url"
									/>
									<div class="img-bottom-mobile">
										<n-button tertiary size="tiny" type="error" @click="handleDel(item.id)">
											删除
										</n-button>
									</div>
								</div>
							</div>
							<div v-if="isUserLoading" class="loading">
								Loading...
							</div>
							<n-pagination
								v-if="userImgList.length!=0"
								v-model:page="userPage"
								class="pageClass"
								:page-count="userTotal"
								simple
								@update:page="getPrivatePic()"
							/>
						</div>
					</n-collapse-item>
					<n-collapse-item name="3">
						<template #header>
							<svg t="1689003386826" class="icon" viewBox="0 0 1024 1024" version="1.1"
									 xmlns="http://www.w3.org/2000/svg" p-id="2140" width="16" height="16"
							>
								<path
									d="M913.75 790.85L724.61 327.06V136.15H751a35.91 35.91 0 0 0 35.91-35.91A35.91 35.91 0 0 0 751 64.33H273a35.91 35.91 0 0 0-35.91 35.91A35.91 35.91 0 0 0 273 136.15h24.1v190.5L110 791.37c-32.42 80.53 26.87 168.3 113.67 168.3h576.61c87.05 0 146.34-88.22 113.47-168.82zM362.2 776a65.1 65.1 0 1 1 64.33-65.87A65.1 65.1 0 0 1 362.2 776z m191.26-194.73a38.67 38.67 0 1 1 38.2-39.12 38.68 38.68 0 0 1-38.2 39.12z"
									fill="#48E1AA" p-id="2141"
								></path>
								<path
									d="M647 248.19c-157.33 0-298.07 71-391.91 182.78L136.63 725.29q-1.12 17.07-1.14 34.42A510.3 510.3 0 0 0 171.26 948a122 122 0 0 0 52.45 11.66h576.57c87.05 0 146.34-88.22 113.47-168.82L724.61 327.06V254a515.73 515.73 0 0 0-77.61-5.81zM362.2 776a65.1 65.1 0 1 1 64.33-65.87A65.1 65.1 0 0 1 362.2 776z m191.26-194.73a38.67 38.67 0 1 1 38.2-39.12 38.68 38.68 0 0 1-38.2 39.12z"
									fill="#3CD38E" p-id="2142"
								></path>
								<path
									d="M767 430.9a434 434 0 0 0-195.35 77.82 38.66 38.66 0 1 1-55 46.87A437.16 437.16 0 0 0 424 693a65.1 65.1 0 0 1-22.76 69.33 440 440 0 0 0-1.14 197.31h400.18c87.05 0 146.34-88.22 113.47-168.82z"
									fill="#1DCE75" p-id="2143"
								></path>
								<path
									d="M589.06 959.67h211.22c87.05 0 146.34-88.22 113.47-168.82l-77.49-190C699.28 666.27 601.75 801 589.06 959.67z"
									fill="#05C46D" p-id="2144"
								></path>
								<path
									d="M469.82 173.07c6.2 10.18-29 21-66.65 44s-63.4 49.3-69.61 39.12 15.42-43.33 53-66.28 77.05-27.02 83.26-16.84z"
									fill="#FFFFFF" p-id="2145"
								></path>
							</svg>
							<n-text style="margin-left: 0.5em" type="success">
								控制台
							</n-text>
						</template>

						<div class="draw-config">
							<n-space vertical size="large">
								<n-steps v-model:current="currentStep" :on-update-current="resetDivStatus">
									<n-step
										title="控制台"
									/>
									<n-step
										title="任务"
									/>
								</n-steps>
								<div v-if="drawConfigDiv">
									<div class="config">
										<n-tabs type="segment" @update:value="handleUpdateStyle" default-value="model">
											<n-tab-pane name="template" tab="模板">
											</n-tab-pane>
											<n-tab-pane name="model" tab="自定义">
											</n-tab-pane>
										</n-tabs>
										<p class="font-show-size" v-if="chooseStyle==='template'">
											请从公开模版中选择一个适合的模板
										</p>
										<p class="font-show-size" v-if="chooseStyle==='model'">
											请配置下方配置项
										</p>
									</div>
									<div class="config" v-show="chooseStyleFlag">
										<p class="config-title">
											选择版本
										</p>
										<n-radio-group v-model:value="checkModel">
											<n-radio-button value="1">
												V1
											</n-radio-button>
											<n-radio-button value="1.1">
												V1.1
											</n-radio-button>
<!--											<n-radio-button value="2" disabled>-->
<!--												V2-->
<!--											</n-radio-button>-->
										</n-radio-group>
										<p class="font-show-size" v-if="checkModel==='1'">
											艺术二维码
										</p>
										<p class="font-show-size" v-if="checkModel==='1.1'">
											修复了V1版本的一些问题
										</p>
<!--										<p class="font-show-size" v-if="checkModel==='2'">-->
<!--											可以创建更具艺术性的二维码-->
<!--										</p>-->
									</div>

									<div class="config" v-show="chooseStyleFlag">
										<p class="config-title">
											图片比例
										</p>
										<n-radio-group v-model:value="checkRatio" disabled>
											<n-radio-button value="1:1">
												<div class="ratio ratio1-1"/>
												1:1
											</n-radio-button>
											<n-radio-button value="4:3">
												<div class="ratio ratio4-3"/>
												4:3
											</n-radio-button>
											<n-radio-button value="3:4">
												<div class="ratio ratio3-4"/>
												3:4
											</n-radio-button>
											<n-radio-button value="16:9">
												<div class="ratio ratio16-9"/>
												16:9
											</n-radio-button>
											<n-radio-button value="9:16">
												<div class="ratio ratio9-16"/>
												9:16
											</n-radio-button>
										</n-radio-group>
										<p class="font-show-size">
											生成不稳定，暂不开放
										</p>
									</div>

									<div class="config">
										<span class="flex-shrink-0 w-[100px] ">二维码明显程度</span>
										<div class="flex-1">
											<NSlider
												v-model:value="drawState.abstract" :keyboard="true" tooltip :marks="abstract" :min="0" :max="1"
												:step="0.01"
											/>
											<p class="font-show-size">
												该数值越小图片更具有艺术性，数值越大则更容易扫描。
											</p>
										</div>
									</div>
									<div class="config" v-show="chooseStyleFlag">
										<span class="flex-shrink-0 w-[100px] ">随机种子数</span>
										<div class="flex-1">
											<div>
												<NSlider
													v-model:value="drawState.seed" :keyboard="true" tooltip :marks="seed" :min="0"
													:max="999999999"
													:step="1"
													disabled
												/>
											</div>
											<p class="font-show-size">
												使用相同的seed将生成构图相似的图像。
											</p>
										</div>
									</div>

									<div class="config">
										<n-tabs type="segment" default-value="chap1">
											<n-tab-pane name="chap1" tab="图片模式">
												<div class="config">
													<span class="flex-shrink-0 w-[100px] ">上传二维码</span>
													<div class="flex-1">

														<n-upload
															action="https://www.mocky.io/v2/5e4bafc63100007100d8b70f"
															:default-file-list="previewFileList"
															:on-update:file-list="handleGetBase64"
															:on-remove="handleRemove"
															accept="image/png, image/jpeg, image/jpg"
															:show-upload-list="false"
															list-type="image-card"
															:show-retry-button="true"
															:default-upload="false"
															:max="1"
															@preview="handlePreview"
														/>
														<n-modal
															v-model:show="showModal"
															preset="card"
															style="width: 600px"
															title="一张很酷的图片"
														>
															<img :src="previewImageUrl" style="width: 100%">
														</n-modal>
													</div>
													<p class="font-show-size">
														上传的二维码尽可能的清晰明显，否则将导致成品无法识别
													</p>
												</div>
											</n-tab-pane>
											<n-tab-pane name="chap2" tab="文本模式">
												<div class="config">
													<n-input
														v-model:value="drawState.qr_content"
														placeholder="输入二维码展示的内容，例如：季夏。 内容应当尽量简洁"
														type="textarea"
														size="small"
													/>
												</div>
											</n-tab-pane>
										</n-tabs>
									</div>
									<div class="config" v-show="chooseStyleFlag">
										<span class="flex-shrink-0 w-[100px] ">选择模型</span>
										<n-grid :x-gap="5" :y-gap="8" :cols="2">
											<n-grid-item v-for="(item, index) in publicModelList" :key="index" :value="item.value"
																	 @click="handleItemClick(index,item.value)"
																	 :class="{'model_grid': selectedIndex === index}"
											>
												<div>
													<img style="border-radius: 10px;" :src="item.previewImg">
													<p class="model_title">{{ item.name }}</p>
												</div>
											</n-grid-item>
										</n-grid>
									</div>

									<div class="config" v-show="chooseStyleFlag">
										<span class="flex-shrink-0 w-[100px] ">创作灵感</span>
										<n-input
											v-model:value="drawState.originalPrompt"
											placeholder="描述你的创作灵感，如:“一个乖巧可爱的女孩，手捧鲜花，穿着蓝色长裙”。可通过AI来优化更多的细节。"
											type="textarea"
											size="small"
											:autosize="{
              minRows: 3,
              maxRows: 5,
            }"
										/>
										<div class="config-btns">
											<n-button strong secondary round type="primary" size="small" :disabled="isEnabledHzy"
																@click="handleAiOptimize"
											>
												优化细节
											</n-button>
											<n-button strong secondary round type="primary" size="small" :disabled="isEnabledTranslate"
																@click="handleTranslate"
											>
												翻译咒语
											</n-button>
										</div>
									</div>
									<div class="config" v-show="chooseStyleFlag">
										<n-input
											v-model:value="drawState.artQrPrompt"
											placeholder="本内容由AI自动生成的，当然你也可以主动编辑或跳过AI优化直接替换，直接替换请使用务必使用英文咒语！"
											type="textarea"
											size="small"
											:autosize="{
												minRows: 3,
												maxRows: 5,
											}"
										/>
									</div>
									<div class="config-btns">
										<n-button strong icon-placement="right" secondary round type="warning" @click="handleRunArtQR">
											<template #icon>
												<n-icon>
													<svg t="1699629934065" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14103" width="64" height="64"><path d="M275.687675 288.499644 216.653254 288.499644 216.653254 226.863686 393.858893 226.863686 393.858893 473.46774 630.092931 226.863686 807.35879 226.863686 275.687675 781.67162Z" p-id="14104" fill="#d5cc23"></path></svg>
												</n-icon>
											</template>
											生成
										</n-button>
<!--										<p style="color: #999999;font-size: 5px;margin-top: 0.5em">-->
<!--											目前该功能还在测试阶段，仅对3.5/4.0会员开放使用-->
<!--										</p>-->
									</div>
								</div>
								<div v-else class="img-outer">
									<div>
										<div class="img-box">
											<img :src="picUrl==''?defaultTemplate:picUrl">
										</div>
									</div>
								</div>
							</n-space>
						</div>
					</n-collapse-item>
				</n-collapse>
			</div>

		</div>
		<div class="draw-config" v-if="!isMobile">
			<n-space vertical size="large">
				<n-steps v-model:current="currentStep" :on-update-current="resetDivStatus">
					<n-step
						title="控制台"
					/>
					<n-step
						title="任务"
					/>
				</n-steps>
				<div v-if="drawConfigDiv">
					<div class="config">
						<n-tabs type="segment" @update:value="handleUpdateStyle" default-value="model">
							<n-tab-pane name="template" tab="模板">
							</n-tab-pane>
							<n-tab-pane name="model" tab="自定义">
							</n-tab-pane>
						</n-tabs>
						<p class="font-show-size" v-if="chooseStyle==='template'">
							请从公开模版中选择一个适合的模板
						</p>
						<p class="font-show-size" v-if="chooseStyle==='model'">
							请配置下方配置项
						</p>
					</div>
					<div class="config" v-show="chooseStyleFlag">
						<p class="config-title">
							选择版本
						</p>
						<n-radio-group v-model:value="checkModel">
							<n-radio-button value="1">
								V1
							</n-radio-button>
							<n-radio-button value="1.1">
								V1.1
							</n-radio-button>
<!--							<n-radio-button value="2" disabled>-->
<!--								V2-->
<!--							</n-radio-button>-->
						</n-radio-group>
						<p class="font-show-size" v-if="checkModel==='1'">
							艺术二维码
						</p>
						<p class="font-show-size" v-if="checkModel==='1.1'">
							修复了V1版本的一些问题
						</p>
<!--						<p class="font-show-size" v-if="checkModel==='2'">-->
<!--							可以创建更具艺术性的二维码-->
<!--						</p>-->
					</div>

					<div class="config" v-show="chooseStyleFlag">
						<p class="config-title">
							图片比例
						</p>
						<n-radio-group v-model:value="checkRatio" disabled>
							<n-radio-button value="1:1">
								<div class="ratio ratio1-1"/>
								1:1
							</n-radio-button>
							<n-radio-button value="4:3">
								<div class="ratio ratio4-3"/>
								4:3
							</n-radio-button>
							<n-radio-button value="3:4">
								<div class="ratio ratio3-4"/>
								3:4
							</n-radio-button>
							<n-radio-button value="16:9">
								<div class="ratio ratio16-9"/>
								16:9
							</n-radio-button>
							<n-radio-button value="9:16">
								<div class="ratio ratio9-16"/>
								9:16
							</n-radio-button>
						</n-radio-group>
						<p class="font-show-size">
							此功能不稳定，暂不开放
						</p>
					</div>

					<div class="config">
						<span class="flex-shrink-0 w-[100px] ">二维码明显程度</span>
						<div class="flex-1">
							<NSlider
								v-model:value="drawState.abstract" :keyboard="true" tooltip :marks="abstract" :min="0" :max="1"
								:step="0.01"
							/>
							<p class="font-show-size">
								该数值越小图片更具有艺术性，数值越大则更容易扫描。取值0-1，步长0.01，默认0.45
							</p>
						</div>
					</div>
					<div class="config" v-show="chooseStyleFlag">
						<span class="flex-shrink-0 w-[100px] ">随机种子数</span>
						<div class="flex-1">
							<div>
								<NSlider
									v-model:value="drawState.seed" :keyboard="true" tooltip :marks="seed" :min="0" :max="999999999"
									:step="1"
									disabled
								/>
							</div>
							<p class="font-show-size">
								使用相同的seed将生成构图相似的图像。
							</p>
						</div>
					</div>

					<div class="config">
						<n-tabs type="segment" default-value="chap1">
							<n-tab-pane name="chap1" value="template" tab="图片模式">
								<div class="config">
									<span class="flex-shrink-0 w-[100px] ">上传二维码</span>
									<div class="flex-1">

										<n-upload
											action="https://www.mocky.io/v2/5e4bafc63100007100d8b70f"
											:default-file-list="previewFileList"
											:on-update:file-list="handleGetBase64"
											:on-remove="handleRemove"
											accept="image/png, image/jpeg, image/jpg"
											:show-upload-list="false"
											list-type="image-card"
											:show-retry-button="true"
											:default-upload="false"
											:max="1"
											@preview="handlePreview"
										/>
										<n-modal
											v-model:show="showModal"
											preset="card"
											style="width: 600px"
											title="一张很酷的图片"
										>
											<img :src="previewImageUrl" style="width: 100%">
										</n-modal>
									</div>
									<p class="font-show-size">
										上传的二维码尽可能的清晰明显，否则将导致成品无法识别
									</p>
								</div>
							</n-tab-pane>
							<n-tab-pane name="chap2" value="model" tab="文本模式">
								<div class="config">
									<n-input
										v-model:value="drawState.qr_content"
										placeholder="输入二维码展示的内容，例如：季夏。 内容应当尽量简洁"
										type="textarea"
										size="small"
									/>
								</div>
							</n-tab-pane>
						</n-tabs>
					</div>
					<div class="config" v-show="chooseStyleFlag">
						<span class="flex-shrink-0 w-[100px] ">选择模型</span>
						<n-grid :x-gap="5" :y-gap="8" :cols="2">
							<n-grid-item v-for="(item, index) in publicModelList" :key="index" :value="item.value"
													 @click="handleItemClick(index,item.value)"
													 :class="{'model_grid': selectedIndex === index}"
							>
								<div>
									<img style="border-radius: 10px;" :src="item.previewImg">
									<p class="model_title">{{ item.name }}</p>
								</div>
							</n-grid-item>
						</n-grid>
					</div>


					<div class="config" v-show="chooseStyleFlag">
						<span class="flex-shrink-0 w-[100px] ">创作灵感</span>
						<n-input
							v-model:value="drawState.originalPrompt"
							placeholder="描述你的创作灵感，如:“一个乖巧可爱的女孩，手捧鲜花，穿着蓝色长裙”。可通过AI来优化更多的细节。"
							type="textarea"
							size="small"
							:autosize="{
              minRows: 3,
              maxRows: 5,
            }"
						/>
						<div class="config-btns">
							<n-button strong secondary round type="primary" size="small" :disabled="isEnabledHzy"
												@click="handleAiOptimize"
							>
								优化细节
							</n-button>
							<n-button strong secondary round type="primary" size="small" :disabled="isEnabledTranslate"
												@click="handleTranslate"
							>
								翻译咒语
							</n-button>
						</div>
					</div>
					<div class="config" v-show="chooseStyleFlag">
						<n-input
							v-model:value="drawState.artQrPrompt"
							placeholder="本内容由AI自动生成的，当然你也可以主动编辑或跳过AI优化直接替换，直接替换请使用务必使用英文咒语！"
							type="textarea"
							size="small"
							:autosize="{
              minRows: 3,
              maxRows: 5,
            }"
						/>
					</div>
					<div class="config-btns">
						<n-button strong icon-placement="right" secondary round type="warning" @click="handleRunArtQR">
							<template #icon>
								<n-icon>
									<svg t="1699629934065" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14103" width="64" height="64"><path d="M275.687675 288.499644 216.653254 288.499644 216.653254 226.863686 393.858893 226.863686 393.858893 473.46774 630.092931 226.863686 807.35879 226.863686 275.687675 781.67162Z" p-id="14104" fill="#d5cc23"></path></svg>
								</n-icon>
							</template>
							生成
						</n-button>
<!--						<p style="color: #999999;font-size: 5px;margin-top: 0.5em">-->
<!--							目前该功能还在测试阶段，仅对3.5/4.0会员开放使用-->
<!--						</p>-->
					</div>
				</div>
				<div v-else class="img-outer">
					<!--					hasRunTask-->
					<div>
						<div class="img-box">
							<img :src="picUrl==''?defaultTemplate:picUrl">
						</div>
					</div>
				</div>
			</n-space>
		</div>
	</div>
</template>

<style scoped>
.masonry {
	display: flex;
	flex-wrap: wrap;
	overflow: auto;
	max-height: 36em;
	justify-content: space-around;
	margin-bottom: 0.5em;
	padding-bottom: 12px;
	width: 100%;
}

.masonry-item {
	width: calc(33.33% - 20px);
	margin: 0.2em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}

.masonry-item-mobile {
	width: 100%;
	margin: 0.2em;
	display: flex;
	height: 100%;
	flex-direction: column;
}

.img-bottom {
	margin-top: 0.2em;
	display: flex;
	width: 100%;
	justify-content: space-around;
}

.img-bottom-mobile {
	margin-top: 0.2em;
	display: flex;
	width: 100%;
	justify-content: space-around;
}

.loading {
	text-align: center;
	margin-top: 20px;
}

.draw-box {
	padding: 0.5em;
	height: 100%;
}

.draw-box > div {
	background: #ffffff;
	padding: 1em;

}

.draw-list {
	width: 65%;
	margin-right: 0.8em;

}

.draw-config {
	flex: 5;
	overflow: auto;
}

.n-radio-button {
	width: 5em !important;
}

.n-radio__label {
	display: flex;
	align-items: center;
}

.ratio {
	border: 1px solid #999999;
	border-radius: 5px;
	text-align: center;
	display: inline-block;

}

.pageClass {
	bottom:0;
	justify-content: center;
	height:5em;
	width:100%;
}

.ratio1-1 {
	height: 1em;
	width: 1em;
	margin: 0 auto;
}

.ratio4-3 {
	height: 0.9em;
	width: 1.2em;
	margin: 0 auto;
}

.ratio3-4 {
	height: 1.2em;
	width: 0.9em;
	margin: -2% auto;
}

.ratio16-9 {
	height: 0.9em;
	width: 1.6em;
	margin: 0 auto;
}

.ratio9-16 {
	height: 1.6em;
	width: 0.9em;
	margin: -10% auto;
}

.config {
	margin-top: 10px;
}

.img-outer {
	display: flex;
	height: 100%;
	flex-direction: column;
}

.img-box {
	display: block;
	height: 60%;
	width: 100%;
}

.img-box img {
	width: 100%;
	height: 100%;
}

.config-btns {
	margin: 0.5em auto;
}

.config-btns .n-button {
	margin: 0 0.5em;
}

.carousel-img {
	width: 100%;
	height: 20em;
	object-fit: cover;
	padding: 0.6em;
}

.model_title {
	text-align: center;
	color: #6e7681;
}

.model_grid {
	border: green solid 2px;
	border-radius: 10px;
}

.font-show-size {
	color: #999999;
	font-size: 12px;
}

</style>
